import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import * as Yup from 'yup';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import DragDropFileUploadArea from '../../UIKit/DragDropFileUploadArea/DragDropFileUploadArea';

import ErrorSVG from '../../../public/media/error.svg';
import ExcelSVG from '../../../public/media/excel-icon.svg';
import CheckedSVG from '../../../public/media/checked.svg';

import { userAtom } from '../../../store/auth';
import { DEFAULT_DATE_FORMAT } from '../../../constants';

import styles from './User.module.scss';
import { CustomTrans, useCustomTranslation } from '../../../useAppTranslate';

type UploadError = { row: number; attribute: string; errors: string[]; email?: string };

type UsersBulkUploadFormProps = {
  submitForm: (formValues: Record<string, any>) => Promise<{ response: { errors?: UploadError[]; message?: string } }>;
};

const Error = ({ type, text }: { type: string; text: React.JSX.Element }) => (
  <div className={styles.form__error}>
    <div className={styles.form__error__heading}>
      <svg>
        <use
          xlinkHref={`${ErrorSVG}#errorSVG`}
          href={`${ErrorSVG}#errorSVG`}
        />
      </svg>
      <p>{type}</p>
    </div>
    <div className={styles.form__error__body}>{text}</div>
  </div>
);

type FormValues = {
  file: File | null;
};
const initialValues: FormValues = {
  file: null,
};

const UsersBulkUploadForm = ({ submitForm }: UsersBulkUploadFormProps) => {
  const { t } = useCustomTranslation();
  const userData = useAtomValue(userAtom);
  const [uploadErrors, setUploadErrors] = useState<null | UploadError[]>(null);
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const {
    values, touched, errors, handleSubmit, setFieldValue, setFieldError, isSubmitting, setTouched,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const { response } = await submitForm(formValues);
      if (response.errors) {
        setUploadErrors(response.errors);
      }
      if (response.message) {
        setUploadErrors([{ row: 0, attribute: 'file', errors: [response.message] }]);
      }
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required(t('A file is required')),
    }),
  });

  useEffect(() => {
    setUploadErrors(null);
  }, [values?.file?.lastModified]);

  const setExcelFile = (files: FileList | null) => {
    if (files?.length) {
      const allowedExtensions = /(\.xlsx)$/i;
      const SIZE_LIMIT = 1024 ** 2;

      switch (true) {
        case !allowedExtensions.exec(files[0]?.name):
          setTouched({ ...touched, file: true }).then(() => setFieldError('file', t('Please select only .xlsx files.')));
          break;
        case files[0].size > SIZE_LIMIT:
          setTouched({ ...touched, file: true }).then(() => setFieldError('file', t('Size limit is 1MB.')));
          break;
        default:
          setFieldValue('file', files[0]);
          break;
      }
    }
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    const { files } = e.dataTransfer;
    setExcelFile(files);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    setExcelFile(files);
  };

  const dateFormat = userData?.user.dateFormat || DEFAULT_DATE_FORMAT;

  const getErrorTitle = (errs: UploadError[]) => {
    const isDoublicatesError = errs?.every((err) => err.attribute === 'email');
    const isFileError = errs?.every((err) => err.attribute === 'file');

    switch (true) {
      case isDoublicatesError:
        return t('Found duplicates');
      case isFileError:
        return t('Structural errors');
      default:
        return t('There are errors in the file');
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      {values.file ? (
        <>
          <div className={classNames(styles.form__file, { [styles.form__file_error]: !!uploadErrors })}>
            <svg className={styles.form__file__svg}>
              <use
                xlinkHref={`${ExcelSVG}#excelIcon`}
                href={`${ExcelSVG}#excelIcon`}
              />
            </svg>
            <div className={styles.form__file__text}>
              <p className={styles.form__file__title}>{values.file?.name}</p>
              {uploadErrors ? (
                <p className={classNames(styles.form__file__subtitle, styles.form__file__subtitle_error)}>{t('An error occurred')}</p>
              ) : (
                <p className={styles.form__file__subtitle}>{`${t('Uploaded')} ${dayjs(values.file?.lastModified).format(dateFormat)}`}</p>
              )}
            </div>
          </div>
          {!uploadErrors && (
            <div className={styles.form__successMsg}>
              <svg>
                <use
                  xlinkHref={`${CheckedSVG}#checkedSVG`}
                  href={`${CheckedSVG}#checkedSVG`}
                />
              </svg>
              <p>{t('File successfully imported')}</p>
            </div>
          )}
        </>
      ) : (
        <DragDropFileUploadArea
          className={styles.form__uploadArea}
          handleDropFile={handleDropFile}
          handleChangeFile={handleChangeFile}
          touched={!!(touched.file && errors.file)}
          error={errors.file}
          value={values.file}
        />
      )}
      {uploadErrors && (
        <Error
          type={getErrorTitle(uploadErrors)}
          text={(
            <>
              {uploadErrors.map((error) => (
                <p
                  key={error.attribute + error.row}
                  className={styles.form__error__item}
                >
                  <CustomTrans
                    components={{
                      cell: <span className={styles.form__error__cell} />,
                      mail: <span className={classNames(styles.form__error__cell, styles.form__error__cell_email)} />,
                    }}
                    values={{
                      row: error.row,
                      attribute: t(error.attribute.replace('_', ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())),
                      email: error?.email,
                    }}
                    defaults={error.errors[0]}
                  />
                </p>
              ))}
            </>
          )}
        />
      )}
      {uploadErrors && (
        <>
          <input
            ref={fileInputRef}
            id='file'
            name='file'
            type='file'
            onChange={handleChangeFile}
            hidden
          />
          <Button
            variant={ButtonVariants.OUTLINED}
            className={styles.form__uploadAgainBtn}
            onClick={() => fileInputRef?.current?.click?.()}
          >
            {t('Upload file again')}
          </Button>
        </>
      )}
      <footer>
        <Button
          type='submit'
          disabled={isSubmitting || !!uploadErrors}
          loading={isSubmitting}
        >
          {t('Save created users')}
        </Button>
      </footer>
    </form>
  );
};

export default UsersBulkUploadForm;
