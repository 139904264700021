import React from 'react';
import Button from '../../UIKit/Button/Button';
import { IRelease, ReleaseNote } from './ReleaseRow';
import { useCustomTranslation } from '../../../useAppTranslate';
import styles from './ReleaseDetails.module.scss';

const ReleaseDetails = ({
  selectedRelease,
  releaseDetails,
  setIsReleaseDrawerOpen,
}: {
  selectedRelease: IRelease | null,
  releaseDetails?: string,
  setIsReleaseDrawerOpen: (state: boolean) => void
}) => {
  const { t } = useCustomTranslation();

  return (
    <section className={styles.releaseDetails}>
      <p className={styles.releaseDetails_title}>{t('Updates and Improvements:')}</p>
      <section className={styles.releaseDetails_content}>
        <ul className={styles.releaseDetails_list}>
          {selectedRelease?.notes?.map((release) => (
            <ReleaseNote
              key={release.id}
              title={release.caption}
              description={release.description}
            />
          ))}
        </ul>
        {releaseDetails && (
          <div
            className={styles.releaseDetails_description}
            dangerouslySetInnerHTML={{ __html: releaseDetails }}
          />
        )}
      </section>
      <Button
        onClick={() => setIsReleaseDrawerOpen(false)}
        className={styles.gotItButton}
      >
        {t('Ok, got it')}
      </Button>
    </section>
  );
};

export default ReleaseDetails;
