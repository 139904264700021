export type UserProfile = {
  salutation: string | null;
  first_name: string;
  last_name: string;
  phone: string | null;
  company: string | null;
  department: string | null;
  role: string | null;
};

export type UserResource = {
  '2fa': boolean;
  id: number;
  email: string;
  name: string;
  profile: UserProfile;
  locale: string | null;
  dateFormat: string;
  numberFormat: string;
  answers?: { [key: string]: string[] };
  passEmpty: boolean
};

export type Domain = {
  caption: string;
  id: number;
};

export type ClientMemberResource = UserResource & {
  roles: RoleResource[];
  domains: Domain[];
};

export type RoleResource = {
  id: number; caption: string; description: string; icon: string; scope?: string; level: number;
};
export interface ListResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: { url?: string; label: string; active: boolean }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
  message?: string;
}

export interface ILatestRelease {
  unread: number;
  latest_release: {
    id: number,
    read: boolean,
    caption: null,
    release_date: Date,
  }
}

export const NO_ROLE = 0;

export enum RoleLevel {
  PROJECT_OWNER = 255,
  EDIT = 200,
  READ_ONLY = 100,
}
