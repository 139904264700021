import {
  getGridStringOperators, GridColDef, GridRenderCellParams, GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Locale } from '../../store/lang.ts';
import { useDebounce } from '../../useDebounce.ts';
import ClippedCell from '../UIKit/Table/ClippedCell.tsx';
import SearchFilter from '../UIKit/Table/Filters/SearchFilter.tsx';
import styles from './MaintainTranslations.module.scss';
import { updateTranslation } from './manageTranslationsAPI.ts';

export const getTranslationsColumns = ({
  t,
  locales,
  page,
  limit,
  updateLocalTranslation,
  updateTranslationUpdateTimestamp,
  isReadOnly,
}: {
  t: TFunction<string, undefined>,
  locales?: Locale[],
  page: number,
  limit: number,
  updateLocalTranslation: (id: number, value: string, locale: string) => void,
  updateTranslationUpdateTimestamp: (value: { [locale: string]: string | null }) => void,
  isReadOnly: boolean
}): GridColDef[] => [
  {
    headerName: '#',
    field: 'orderNumber',
    maxWidth: 50,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
      params.row.timestamp
        ? <div className='translationsTimestamp' />
        : <span>{(params.api.getAllRowIds().indexOf(params.id) + 1) + ((page - 1) * limit) - 1}</span>
    ),
  },
  {
    headerName: t('Scope'),
    field: 'scope',
    minWidth: 136,
    maxWidth: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (params.row.timestamp ? <div className='translationsTimestamp' /> : <ClippedCell value={params.value} />),
  },
  {
    headerName: t('Field Label Backend'),
    field: 'value',
    minWidth: 320,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (params.row.timestamp ? <div className='translationsTimestamp' /> : <ClippedCell value={params.value} />),
  },
  ...(Array.isArray(locales)
    ? locales.map((locale) => ({
      headerName: `${locale.caption} (${locale.locale.toUpperCase()})`,
      field: locale.locale,
      flex: 1,
      minWidth: 250,
      // sortable: Number(question.max) === 1,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
        const [value, setValue] = useState(params.value || '');
        const [prevValue, setPrevValue] = useState(value);
        const debouncedValue = useDebounce<string>(value, 1000);

        useEffect(() => {
          setValue(params.value);
          setPrevValue(params.value);
        }, [params.value]);

        useEffect(() => {
          prevValue !== debouncedValue
            && updateTranslation({
              id: params.row.id,
              value: {
                caption: {
                  [params.field]: debouncedValue,
                },
              },
              t,
            }).then((data) => {
              const localesUpdateTime = data?.reduce((acc, curr) => ({
                ...acc,
                [curr.locale]: curr.latest_update,
              }), {} as { [locale: string]: string | null });
              localesUpdateTime && Object.keys(localesUpdateTime).length && updateTranslationUpdateTimestamp(localesUpdateTime);
              updateLocalTranslation(params.row.id, debouncedValue, locale.locale);
            });
        }, [debouncedValue]);

        return params.row.timestamp ? (
          <div className={classNames('translationsTimestamp', styles.timestampCell)}>
            {t('Updated')}
            {': '}
            <mark>{value}</mark>
          </div>
        ) : (
          <TextareaAutosize
            className={classNames(styles.translationInput, 'fullSizeCell')}
            value={value}
            onChange={(e) => {
              setPrevValue(value);
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation(); // prevent textarea unfocusing
            }}
            disabled={isReadOnly}
            placeholder={t(isReadOnly
              ? '{{language}} translation'
              : 'Add {{language}} translation', { language: locale.locale.toUpperCase() })}
          />
        );
      },
      filterable: true,
      filterOperators: getGridStringOperators().map((operator) => ({
        ...operator,
        InputComponent: SearchFilter,
      })),
    }))
    : []),
];
