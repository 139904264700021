import React from 'react';
import classNames from 'classnames';
import styles from './ReleaseNotes.module.scss';
import PopoverOptions, { IPopoverOptions } from '../../UIKit/PopoverOptions/PopoverOptions';
import { PopoverPlacement } from '../../UIKit/Popover/Popover';
import { useCustomTranslation } from '../../../useAppTranslate';

export interface IReleaseNote {
  id: number,
  caption: string,
  description: string,
}

export interface IRelease {
  id: number,
  read: boolean,
  description: string, // html
  notes?: IReleaseNote[],
  published?: boolean,
  caption: string,
  release_date: string,
}

const ReleaseRow = ({
  releaseId,
  read = true,
  title,
  description,
  contextMenuOptions,
  onClick,
}: {
  releaseId: number,
  read: boolean,
  title: string,
  description: string,
  contextMenuOptions: IPopoverOptions[],
  onClick: (releaseId: number, read: boolean) => void,
}) => {
  const { t } = useCustomTranslation('global');

  return (
    <li
      className={styles.releases_list__row}
    >
      <button
        type='button'
        onClick={() => {
          onClick(releaseId, read);
        }}
        aria-label={`${t('Open')} ${title} ${t('release')}`}
        className={classNames(styles.releases_list__content, { [styles.read]: read })}
      >
        <p className={styles.releases_list__content_title}>
          {title}
          {!read && <span className={styles.badge}>{t('New')}</span>}
        </p>
        <p className={styles.releases_list__content_description}>{description}</p>
      </button>
      {!read && (
        <PopoverOptions
          options={contextMenuOptions}
          placement={PopoverPlacement.CONTEXT_MENU}
          className={styles.release__popover}
        />
      )}
    </li>
  );
};

export default ReleaseRow;

export const ReleaseNote = ({
  title,
  description,
}: {
  title: string,
  description: string, // html
}) => (
  <li className={styles.releases_list__row}>
    <div className={classNames(styles.readOnly, styles.releases_list__content)}>
      <p className={styles.releases_list__content_title}>{title}</p>
      <div
        className={styles.releases_list__content_description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </li>
);
