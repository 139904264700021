import classNames from 'classnames';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';

import { Tooltip } from '@mui/material';

import Drawer from '../../../../UIKit/Drawer/Drawer';
import Loader from '../../../../Loader/Loader';

import StartDateSVG from '../../../../../public/media/summary/parameters/startDate.svg';
import EndDateSVG from '../../../../../public/media/summary/parameters/endDate.svg';
import CustomerSVG from '../../../../../public/media/summary/parameters/customer.svg';
import BudgetSVG from '../../../../../public/media/summary/parameters/budget.svg';
import SystemsSVG from '../../../../../public/media/summary/parameters/systems.svg';
import EmployeesSVG from '../../../../../public/media/summary/parameters/employees.svg';
import ProcessesSVG from '../../../../../public/media/summary/parameters/processes.svg';
import PrioritySVG from '../../../../../public/media/summary/parameters/priority.svg';
import AngleDownSVG from '../../../../../public/media/angle-down.svg';

import apiClient from '../../../../../apiClient';
import { ProjectResource } from '../../../Projects/types';
import { userAtom } from '../../../../../store/auth';
import { Questions } from '../../../../UserForm/types';
import { QuestionName } from '../../NewProjectParameters/NewProjectParameters';
import { formatNumberAsEuro } from '../../../../../utils/format-number-to-euro';
import { DEFAULT_NUMBER_FORMAT, NUMBER_FORMAT } from '../../../../../constants';
import { ProjectPermissions, UserProps } from '../../../Login/user.props';
import { NewProjectContext } from '../../types';

import styles from './SummaryDashboard.module.scss';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { PopoverPlacement } from '../../../../UIKit/Popover/Popover';
import PopoverOptions from '../../../../UIKit/PopoverOptions/PopoverOptions';
import { notify, NotificationStatus } from '../../../../../store/notifications';
import { projectEditAccess } from '../../../../../store/project';
import { useCustomTranslation } from '../../../../../useAppTranslate';

const rows = [
  {
    id: 'startDate',
    title: 'Project start date',
    icon: StartDateSVG,
  },
  {
    id: 'finishDate',
    title: 'Project end date',
    icon: EndDateSVG,
  },
  {
    id: 'customer',
    title: 'Customer',
    icon: CustomerSVG,
  },
  {
    id: 'budget',
    title: 'Budget',
    icon: BudgetSVG,
  },
  {
    id: 'systems',
    title: 'Number of systems involved',
    icon: SystemsSVG,
  },
  {
    id: 'employees',
    title: 'Number of project employees',
    icon: EmployeesSVG,
  },
  {
    id: 'processes',
    title: 'Processes / Business Domains',
    icon: ProcessesSVG,
  },
];

const priorities = [
  {
    id: 1,
    caption: 'Low',
  },
  {
    id: 2,
    caption: 'Medium',
  },
  {
    id: 3,
    caption: 'High',
  },
];

const swrConfig = {
  keepPreviousData: false,
  revalidateOnFocus: false,
  revalidateOnMount: true,
};

const getAnswer = (questions: Questions[], answers: ProjectResource['answers'], questionName: QuestionName) => {
  const question = questions.find((item) => item.name === questionName);
  if (question) {
    const answersId = answers[question.id];
    return answersId.map((id) => question.answers.find((option) => option.id === id)?.caption).join(', ');
  } else {
    return '';
  }
};

const adapter = (
  data: ProjectResource,
  questions: Questions[] | undefined,
  userData: Omit<UserProps, 'permissions'> | null,
  numberFormat: typeof NUMBER_FORMAT[string],
): Record<(typeof rows)[number]['id'], string | undefined> | null => (questions && userData ? ({
  startDate: dayjs(data.begin).format(userData?.user?.dateFormat || 'DD.MM.YYYY'),
  finishDate: dayjs(data.end).format(userData?.user?.dateFormat || 'DD.MM.YYYY'),
  customer: `ID#${data?.customer?.id} ${data?.customer?.name}`,
  budget: formatNumberAsEuro(data.budget, numberFormat),
  systems: getAnswer(questions, data.answers, QuestionName.SYSTEMS_INVOLVED),
  employees: getAnswer(questions, data.answers, QuestionName.PROJECT_EMPLOYEES),
  processes: getAnswer(questions, data.answers, QuestionName.PROCESSES_AFFECTED),
  description: data.description,
  priorityCaption: data?.priority?.caption,
}) : null);

const SummaryParameters = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const [project, setProject] = useAtom(projectAtom);
  const { t, i18n } = useCustomTranslation();
  const { clientId, projectId } = useParams();
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const userData = useAtomValue(userAtom);
  const numberFormat = userData?.user?.numberFormat ? NUMBER_FORMAT[userData?.user?.numberFormat] : DEFAULT_NUMBER_FORMAT;

  const { data: questions, isLoading: areQuestionsLoading } = useSWR(
    ['questions/project', i18n.language],
    ([url]) => apiClient
      .get<{ data: Questions[] }>(url)
      .then(({ response }) => response.data),
    swrConfig,
  );

  const {
    data: parameters, isLoading, isValidating, mutate: updateParameters,
  } = useSWR(
    [`clients/${clientId}}/projects/${projectId}?with=customer,answers`, i18n.language, questions, userData, project?.status?.state],
    async ([url]) => (questions && userData && project?.status?.state !== StatusState.PENDING
      ? apiClient
        .get<{ data: ProjectResource }>(url)
        .then(
          ({ response }) => (adapter(response.data, questions, userData, numberFormat)),
        )
      : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onError: (error) => {
        console.error(error);
      },
      fallbackData: adapter(project, questions, userData, numberFormat),
    },
  );

  const changePriorityHandler = (id: number) => () => {
    try {
      apiClient
        .put<{ data: ProjectResource }>(`projects/${projectId}/priority`, { body: JSON.stringify({ priority: id }) })
        .then(({ response }) => {
          const { data } = response;

          updateParameters();
          setProject(current => ({ ...current, priority: data.priority }));
          notify({ text: { title: t('Success'), body: t('Project priority has been changed') }, status: NotificationStatus.SUCCESS });
        });
    } catch (error) {
      console.error(error);
      notify({ text: { body: error?.message ?? '', title: t('An error occurred on priority changing') } });
    }
  };

  const getPriorityOptions = () => priorities.map((priority) => ({
    id: priority.id,
    title: <p>{t(priority.caption)}</p>,
    handler: changePriorityHandler(priority.id),
  }));

  const storedProjectEditAccess = useAtomValue(projectEditAccess);
  const isAbleToEdit = project?.permissions?.includes?.(ProjectPermissions.UPDATE);
  let isReadOnly: boolean;
  if (projectId) {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes?.(ProjectPermissions.VIEW))
    || !storedProjectEditAccess?.isEditingAvailableShowOnUI || project?.status?.state === StatusState.BLOCKED;
  } else {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes?.(ProjectPermissions.VIEW))
    || project?.status?.state === StatusState.BLOCKED;
  }

  return (
    <section className={classNames(styles.section, styles.parameters, {
      [styles.section_loading]: isLoading || areQuestionsLoading,
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading || areQuestionsLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Project parameters')}</h3>
            <button
              type='button'
              className={classNames('link', styles.detailsButton)}
              onClick={() => setIsDescriptionOpen(true)}
            >
              {t('Open description')}
            </button>
          </header>
          <div className={styles.parameters__content}>
            {rows.map((item) => (
              <div
                className={styles.parameter}
                key={item.id}
              >
                <img
                  src={item.icon}
                  className={styles.parameter__icon}
                  alt=''
                />
                <Tooltip
                  arrow
                  title={
                    (parameters?.[item.id]?.length ?? 0) > 20 ? parameters?.[item.id] : null
                  }
                >
                  <p className={styles.parameter__value}>{parameters?.[item.id] ?? ''}</p>
                </Tooltip>
                <p className={styles.parameter__title}>{t(item.title)}</p>
              </div>
            ))}
            <div
              className={styles.parameter}
            >
              <img
                src={PrioritySVG}
                className={styles.parameter__icon}
                alt=''
              />
              {isReadOnly ? (
                <p className={classNames(styles.parameter__value, styles.parameter__priorityValue)}>
                  {parameters?.priorityCaption ?? t('Unselected')}
                </p>
              ) : (
                <PopoverOptions
                  customButton={(
                    <p className={classNames(styles.parameter__value, styles.parameter__priorityValue)}>
                      {parameters?.priorityCaption ?? t('Unselected')}
                      <svg className={styles.downloadButton__arrow}>
                        <use
                          xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                          href={`${AngleDownSVG}#angleDownSVG`}
                        />
                      </svg>
                    </p>
                  )}
                  options={getPriorityOptions()}
                  placement={PopoverPlacement.CONTEXT_MENU_LEFT}
                />
              )}
              <p className={styles.parameter__title}>{t('Priority')}</p>
            </div>
          </div>
        </>
      )}
      <Drawer
        isOpen={isDescriptionOpen}
        setIsOpen={setIsDescriptionOpen}
        title={t('About project')}
        className={styles.descriptionDrawer}
      >
        {parameters?.description
          ? <div dangerouslySetInnerHTML={{ __html: parameters?.description }} /> : t('No description')}
      </Drawer>
    </section>
  );
};

export default SummaryParameters;
