import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const usePage = (saveInHistory: boolean = true) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page') || '1', 10);

  const [page, setPage] = useState<number>(initialPage);

  const updatePage = (newPage: number) => {
    const params = new URLSearchParams(location.search);
    newPage === 1 ? params.delete('page') : params.set('page', String(newPage));
    navigate({
      search: params.toString(),
    }, {
      replace: !saveInHistory,
    });
    setPage(newPage);
  };

  useEffect(() => {
    if (page !== initialPage) {
      updatePage(initialPage);
    }
  }, [initialPage]);

  return { page, updatePage };
};

export default usePage;
