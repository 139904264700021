import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';

import apiClient from '../../../../../apiClient';
import { usePermissions } from '../../../../../usePermission';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';
import { Permissions } from '../../../Login/user.props';
import { IProjectRisk } from '../ProjectRisks/types';
import { useCustomTranslation } from '../../../../../useAppTranslate';
import styles from './SummaryDashboard.module.scss';

const SummaryGeneralProjectRisk = () => {
  const { t, i18n } = useCustomTranslation();
  const { projectId, clientId } = useParams();
  const { hasPermission } = usePermissions();
  const hasPermissionToManageRisks = hasPermission(Permissions.RISK_CREATE);

  const { data: risks, isLoading, isValidating } = useSWR(
    [`projects/${projectId}/risks`, i18n.language],
    async ([url]) => (apiClient
      .get<{ data: IProjectRisk[] }>(url)
      .then(({ response }) => response.data)
    ),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.generalProjectRisks, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('General project risk')}</h3>
            {hasPermissionToManageRisks && (
              <Link
                to={`/d/client/${clientId}/project/${projectId}/manage-risks`}
                type='button'
                className={classNames('link', styles.detailsButton)}
              >
                {t('Manage risks')}
              </Link>
            )}
          </header>

          {risks?.length
            ? (
              <div className={styles.generalProjectRisks__list}>
                {risks?.map((item, index) => (
                  <div
                    className={styles.generalProjectRisks__list__row}
                    key={item.id}
                  >
                    <span className={styles.order}>{index + 1}</span>
                    <p>{item.caption}</p>
                  </div>
                ))}
              </div>
            )
            : <EmptyList title={t('Risks not yet added')} />}
        </>
      )}
    </section>
  );
};

export default SummaryGeneralProjectRisk;
