import { useAtom, useSetAtom } from 'jotai';
import { useLocation, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useEffect, useRef } from 'react';
import Button, { ButtonIconPosition } from '../UIKit/Button/Button';
import { editAccessGererator, pollingSettingsAtom, projectEditAccess } from '../../store/project';
import EditSVG from '../../public/media/edit-icon.svg';
import apiClient from '../../apiClient';
import { notify } from '../../store/notifications';
import { UserThatCurrentlyEditingInfo } from '../pages/Projects/types';
import { useCustomTranslation } from '../../useAppTranslate';

import styles from './ProjectEditingNotAvailableHeaderAlert.module.scss';

const ProjectEditingNotAvailableHeaderAlert = () => {
  const { t, i18n } = useCustomTranslation('global');
  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);
  const setPollingSettings = useSetAtom(pollingSettingsAtom);
  const params = useParams();
  const location = useLocation();
  const isProjectTrackingOverviewPage = location.pathname.includes('tracking');

  const triggerRefreshProjectTrackingOverviewData = () => {
    mutate([`projects/${params?.projectId}/jira-integrated-tasks?page=all`, i18n.language, 'schedule']);
  };
  const getEditAccess = async () => {
    try {
      const { statusCode, response } = await apiClient
        .get<{
        data: UserThatCurrentlyEditingInfo,
      }>(`projects/${params?.projectId}/edit-mode`);

      if (statusCode === 200) {
        // to get up-to-date information, if someone has made any edits.
        isProjectTrackingOverviewPage && triggerRefreshProjectTrackingOverviewData();
        setPollingSettings(prev => ({ ...prev, isTriggered: true }));
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: true,
          isEditingAvailableShowOnUI: true,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId: Number(params?.projectId),
        });
      } else if (statusCode === 409) {
        setStoredProjectEditAccess(
          editAccessGererator({ type: 'SET_EDITING_UNAVAILABLE', payload: { user: response?.data, projectId: Number(params?.projectId) } }),
        );
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const notificationIsVisible = params?.projectId
    && !storedProjectEditAccess?.isEditingAvailableShowOnUI && !storedProjectEditAccess?.isViewMode;

  const projectEditAlertWrapper = useRef<HTMLDivElement | null>(null);

  const setRealViewportSize = () => {
    const height = projectEditAlertWrapper?.current?.clientHeight;
    if (height) {
      document.documentElement.style.setProperty('--project-edit-notification-height', `${height}px`);
    } else {
      document.documentElement.style.setProperty('--project-edit-notification-height', '0px');
    }
  };

  useEffect(() => {
    setRealViewportSize();
    window.addEventListener('resize', setRealViewportSize);

    return () => {
      window.removeEventListener('resize', setRealViewportSize);
      document.documentElement.style.setProperty('--project-edit-notification-height', '0px');
    };
  }, [notificationIsVisible, storedProjectEditAccess?.isEditingAvailable]);

  useEffect(() => {
    setRealViewportSize();
  }, [storedProjectEditAccess?.isEditingAvailable]);

  return notificationIsVisible && (
    <div
      className={styles.alertWrapper}
      ref={projectEditAlertWrapper}
    >
      <p className={styles.alertWrapper__message}>
        {storedProjectEditAccess?.isEditingAvailable ? (
          t('Project editing is available!')
        ) : storedProjectEditAccess?.userThatCurrentlyEditing?.name ? (
          <>
            {t('Project editing is not available. User')}
            {storedProjectEditAccess?.userThatCurrentlyEditing?.name && (
              <span className={styles.alertWrapper__message__user}>
                {storedProjectEditAccess?.userThatCurrentlyEditing?.name}
                {', '}
                {storedProjectEditAccess?.userThatCurrentlyEditing?.email}
              </span>
            )}
            {t('is currently editing this project. Please wait in the edit queue.')}
          </>
        ) : (t('Checking access for editing...'))}
      </p>
      <Button
        className={styles.alertWrapper__button}
        iconPosition={ButtonIconPosition.LEFT}
        iconSize={{ width: 12, height: 12 }}
        onClick={getEditAccess}
        disabled={!storedProjectEditAccess?.isEditingAvailable}
        icon={(
          <svg>
            <use
              xlinkHref={`${EditSVG}#editSVG`}
              href={`${EditSVG}#editSVG`}
            />
          </svg>
        )}
      >
        {t('Edit project')}
      </Button>
    </div>
  );
};

export default ProjectEditingNotAvailableHeaderAlert;
