import React, { useState } from 'react';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { Link, useParams } from 'react-router-dom';
import styles from './GateDeliverable.module.scss';
import { Deliverable } from '../../../NewProjectDeliverables/types.ts';
import DragSVG from '../../../../../../public/media/summary/quality-gates/drag.svg';
import WarningArrowSVG from '../../../../../../public/media/summary/quality-gates/warning-arrow.svg';
import AttachSVG from '../../../../../../public/media/attach.svg';
import WarningSVG from '../../../../../../public/media/warning.svg';
import { userAtom } from '../../../../../../store/auth.ts';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants.ts';
import { useCustomTranslation } from '../../../../../../useAppTranslate.tsx';

type GateDeliverableProps = {
  deliverable: Deliverable,
  gateId: number,
  gateDueDate: string,
  isDragAllowed?: boolean,
  draggingElement?: boolean,
  isRescticted: boolean
};

const GateDeliverable = ({
  deliverable, gateId, gateDueDate, isDragAllowed, draggingElement, isRescticted,
}: GateDeliverableProps) => {
  const { t } = useCustomTranslation();
  const { clientId, projectId } = useParams();

  const userData = useAtomValue(userAtom);
  const [isVisible, setIsVisible] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: deliverable.id,
    data: { deliverable, gateId, gateDueDate },
  });

  const dndStyles = { transform: CSS.Transform.toString(transform), transition };
  const hasWarning = dayjs(deliverable.due_date).isAfter(dayjs(gateDueDate));
  const tooltipProps = !hasWarning ? { open: false } : {};

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      className={classNames(styles.deliverable, {
        [styles.isDragging]: isDragging,
        [styles.draggingElement]: draggingElement,
        [styles.isRestricted]: isRescticted,
      })}
      ref={setNodeRef}
      style={dndStyles}
      {...attributes}
    >
      <div className={styles.deliverable__body}>
        <Tooltip
          arrow
          title={<p>{t('Deliverable due date after the quality gate date')}</p>}
          placement='bottom-start'
          {...tooltipProps}
        >
          <div className={classNames(styles.deliverable__info, {
            [styles.warning]: hasWarning,
          })}
          >
            <span>{dayjs(deliverable.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}</span>
            {hasWarning && (
              <svg className={styles.warningArrowSvg}>
                <use
                  xlinkHref={`${WarningArrowSVG}#warningArrowSVG`}
                  href={`${WarningArrowSVG}#warningArrowSVG`}
                />
              </svg>
            )}
            {deliverable.links_count ? (
              <>
                <span className={styles.divider} />
                <svg className={styles.attachSvg}>
                  <use
                    xlinkHref={`${AttachSVG}#attachSVG`}
                    href={`${AttachSVG}#attachSVG`}
                  />
                </svg>
                <span>{`${deliverable.links_count} ${t('documents')}`}</span>
              </>
            ) : null}
          </div>
        </Tooltip>
        <Link
          to={`/d/client/${clientId}/project/${projectId}/gate/${gateId}?active=${deliverable.id}`}
        >
          {deliverable.caption}
        </Link>
      </div>
      <div className={styles.deliverable__draggable}>
        {deliverable?.task?.task_at_risk && (
          <span className={styles.riskWarning}>
            <svg className={styles.attachSvg}>
              <use
                xlinkHref={`${WarningSVG}#warningSVG`}
                href={`${WarningSVG}#warningSVG`}
              />
            </svg>
            {t('Task at risk')}
          </span>
        )}
        {((isVisible && isDragAllowed) || draggingElement) && (
          <button
            type='button'
            {...listeners}
          >
            <svg>
              <use
                xlinkHref={`${DragSVG}#dragSVG`}
                href={`${DragSVG}#dragSVG`}
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default GateDeliverable;
