import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAtom, useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import CheckedSVG from '../../../public/media/checked.svg';
import SubHeader from '../../SubHeader/SubHeader';
import Button, { ButtonSize } from '../../UIKit/Button/Button';

import styles from './ReleaseNotes.module.scss';
import ReleaseRow, { IRelease } from './ReleaseRow';
import apiClient from '../../../apiClient';
import Loader from '../../Loader/Loader';
import { latestReleaseAtom, releaseBannerAtom, userAtom } from '../../../store/auth';
import { DEFAULT_DATE_FORMAT } from '../../../constants';
import Drawer from '../../UIKit/Drawer/Drawer.tsx';
import ReleaseDetails from './ReleaseDetails.tsx';
import { NotificationStatus, notify } from '../../../store/notifications.ts';
import EmptyList from '../../EmptyList/EmptyList.tsx';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const ReleaseNotes = () => {
  const { t, i18n } = useCustomTranslation('global');
  const userData = useAtomValue(userAtom);
  const [isReleaseDrawerOpen, setIsReleaseDrawerOpen] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState<IRelease | null>(null);
  const [latestRelease, setLatestRelease] = useAtom(latestReleaseAtom);
  const [releaseBanner, setReleaseBanner] = useAtom(releaseBannerAtom);
  const [isLoadingRelease, setIsLoadingRelease] = useState(false);
  const [isReleaseError, setIsReleaseError] = useState(false);

  const dateFormat = userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT;

  const { data: allReleases, isLoading, mutate } = useSWR(['releases', i18n.language], ([url]) => apiClient.get<{ data: IRelease[] }>(url)
    .then(({ statusCode, response }) => {
      if (statusCode === 200) {
        return response.data.map((item) => {
          const releaseDate = item.release_date && dayjs(item.release_date).format(dateFormat);
          return { ...item, release_date: `${t('From')} ${releaseDate}` };
        });
      } else {
        throw new Error(t('Something went wrong'));
      }
    })
    .catch((e) => {
      notify(e?.message ? { status: NotificationStatus.ERROR, text: { body: e.message } } : {});
      console.error(e);
    }), {
    revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false,
  });
  const IS_NOT_EMPTY_LIST = !!allReleases?.length;

  const updateNotification = (type: 'single' | 'all') => {
    if (latestRelease && typeof latestRelease.unread === 'number') {
      const reduceCondition = type === 'single' ? latestRelease.unread - 1 : 0;
      setLatestRelease(prev => (prev ? { ...prev, unread: reduceCondition } : null));
    }
  };

  const updateReleaseById = (comparedId: number) => {
    if (IS_NOT_EMPTY_LIST) {
      const updatedData = allReleases.map((item) => (item.id === comparedId ? { ...item, read: true } : item));
      mutate(updatedData, { revalidate: false });
      updateNotification('single');
    }
  };

  useEffect(() => {
    if (latestRelease?.latest_release?.id && releaseBanner.isBannerRead) {
      updateReleaseById(latestRelease?.latest_release?.id);
    }
  }, [releaseBanner.isBannerRead]);

  const closeReleaseBanner = (compareReleaseId: number) => {
    if (latestRelease?.latest_release?.id === compareReleaseId && releaseBanner.isBannerShown) {
      setReleaseBanner((prev) => ({ ...prev, isBannerShown: false }));
    }
  };

  const markAsReadRelease = async (releaseId: number) => {
    await apiClient.put(`releases/${releaseId}/markAsRead`)
      .then(({ statusCode }) => {
        if (statusCode === 204) {
          updateReleaseById(releaseId);
          // if the release banner is shown => close it
          closeReleaseBanner(releaseId);
        } else {
          throw new Error(t('Something went wrong'));
        }
      })
      .catch((e) => {
        notify({ status: NotificationStatus.ERROR, text: { body: t(e.message) } });
        console.error(e);
      });
  };

  const getReleaseById = async (releaseId: number, read: boolean) => {
    setIsReleaseDrawerOpen(true);
    setIsLoadingRelease(true);
    await apiClient.get<{ data: IRelease }>(`releases/${releaseId}`)
      .then(({ statusCode, response }) => {
        if (statusCode === 200) {
          setSelectedRelease(response.data);
          if (!read) {
            markAsReadRelease(releaseId);
          }
        } else {
          notify({ status: NotificationStatus.ERROR, text: { body: t('Something went wrong') } });
          throw new Error(t('Something went wrong'));
        }
      })
      .catch((e) => {
        setIsReleaseError(true);
        notify(e?.message ? { status: NotificationStatus.ERROR, text: { body: e.message } } : {});
        console.error(e);
      })
      .finally(() => setIsLoadingRelease(false));
  };

  const markedAllAsRead = async () => {
    await apiClient.post('releases/markAsReadAll', {
      body: JSON.stringify(allReleases?.map((item) => item.id)),
    }).then(({ statusCode }) => {
      if (statusCode === 204) {
        if (IS_NOT_EMPTY_LIST) {
          const updatedData = allReleases.map((item) => ({ ...item, read: true }));
          mutate(updatedData, { revalidate: false });
          updateNotification('all');
          if (latestRelease?.latest_release?.id) {
            setReleaseBanner((prev) => ({ ...prev, isBannerShown: false }));
          }
        }
      } else {
        throw new Error(t('Something went wrong'));
      }
    })
      .catch((e) => {
        notify(e?.message ? { status: NotificationStatus.ERROR, text: { body: e.message } } : {});
        console.error(e);
      });
  };

  const contextMenuOptions = (onMarkAsRead: () => void) => [
    {
      id: 0,
      title: (
        <div className={styles.action__option}>
          <svg className='tableRowActionIcon'>
            <use
              xlinkHref={`${CheckedSVG}#checkedSVG`}
              href={`${CheckedSVG}#checkedSVG`}
            />
          </svg>
          <p>{t('Mark as read')}</p>
        </div>
      ),
      handler: onMarkAsRead,
    },
  ];

  const IS_SOME_UNREAD = IS_NOT_EMPTY_LIST && allReleases?.some((release) => !release.read);

  return (
    <div className={styles.releases}>
      <Drawer
        isOpen={isReleaseDrawerOpen}
        setIsOpen={setIsReleaseDrawerOpen}
        title={selectedRelease?.caption}
        subTitle={`${t('From')} ${dayjs(selectedRelease?.release_date).format(dateFormat)}`}
        className={styles.releaseDrawer}
        contentClassName={styles.releaseDrawerContent}
        loading={isLoadingRelease}
        error={isReleaseError}
      >
        <ReleaseDetails
          selectedRelease={selectedRelease}
          releaseDetails={selectedRelease?.description}
          setIsReleaseDrawerOpen={setIsReleaseDrawerOpen}
        />
      </Drawer>
      <SubHeader
        fallbackLink='/'
        title={t('Release Notes')}
      />
      {isLoading && <div className={styles.loader}><Loader /></div>}
      {!isLoading && (
        <section className={styles.releases_wrap}>
          <header className={styles.releases_header}>
            <h5 className={styles.releases_header__title}>{t('Latest Notifications')}</h5>
            {IS_SOME_UNREAD && (
              <Button
                type='button'
                size={ButtonSize.SMALL}
                className={styles.markedAllButton}
                onClick={markedAllAsRead}
                icon={(
                  <svg>
                    <use
                      xlinkHref={`${CheckedSVG}#checkedSVG`}
                      href={`${CheckedSVG}#checkedSVG`}
                    />
                  </svg>
                )}
                iconSize={{ width: 12, height: 12 }}
              >
                {t('Mark all as read')}
              </Button>
            )}
          </header>
          {IS_NOT_EMPTY_LIST ? (
            <ul className={styles.releases_list}>
              {allReleases.map((release) => (
                <ReleaseRow
                  key={release.id}
                  releaseId={release.id}
                  read={release.read}
                  title={release.caption}
                  description={release.release_date}
                  contextMenuOptions={contextMenuOptions(
                    () => markAsReadRelease(release.id),
                  )}
                  onClick={getReleaseById}
                />
              ))}
            </ul>
          ) : (
            <EmptyList title={t('No new notifications yet')} />
          )}
        </section>
      )}
    </div>
  );
};

export default ReleaseNotes;
