import React, { useState } from 'react';
import useSWR from 'swr';
import classNames from 'classnames';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';

import { SelectChangeEvent } from '@mui/material';
import Avatar from '../../ColoredAvatar/ColoredAvatar';
import AddTeamMemberForm, { AddTeamMemberFormType } from './AddTeamMemberForm';
import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import Drawer from '../../UIKit/Drawer/Drawer';
import EntityBaseModal from '../../EntityBaseModal/EntityBaseModal';
import Loader from '../../Loader/Loader';
import ProjectTeamMember from './ProjectTeamMember';
import SubHeader from '../../SubHeader/SubHeader';
import EditMemberAccessForm from './EditMemberAccessForm.tsx';
import ConfirmPresenceModal from '../../ConfirmPresenceModal/ConfirmPresenceModal.tsx';

import apiClient from '../../../apiClient';

import {
  ClientMemberResource, Domain, NO_ROLE, RoleLevel, RoleResource,
} from '../Users/UsersList/types';
import { useRoutePrefix } from '../../../usePermission.ts';
import useEditAccessHandler from '../../../utils/useEditAccessHandler.ts';
import { userAtom } from '../../../store/auth.ts';
import { ProjectResource } from '../Projects/types.ts';
import { StatusState } from '../../UIKit/StatusLabel/types.ts';
import { projectEditAccess } from '../../../store/project.ts';
import { Option } from '../../UIKit/Select/Select.tsx';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

import EmptyUserListSVG from '../../../public/media/empty-user-list.svg';

import styles from './ProjectTeamManagment.module.scss';

const updateMemberRole = async (projectId: string, memberId: number, role: number, domains: Array<number> = []) => {
  try {
    await apiClient
      .put(`projects/${projectId}/members/${memberId}`, { body: JSON.stringify({ role, domains }) });
  } catch (e) {
    console.error(e);
  }
};

const MEMBER_MODAL_DEFAULT_VALUE = {
  isVisible: false,
  selectedMember: null,
};

const getLevelByRoleId = (id: number, roles?: RoleResource[]): number | undefined => roles?.find(role => role.id === id)?.level;

const ProjectTeamManagment = () => {
  const { project } = useLoaderData() as { project: ProjectResource };
  const { projectId, clientId } = useParams();
  const { t, i18n } = useCustomTranslation();
  const prefix = useRoutePrefix();
  const [isNewMemberDrawerOpen, setIsNewMemberDrawerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const userData = useAtomValue(userAtom);

  const [projectOwnerConfirmModal, setProjectOwnerConfirmModal] = useState<{
    isVisible: boolean;
    selectedMember: { id: number, role: number } | null;
  }>(MEMBER_MODAL_DEFAULT_VALUE);

  const [memberModal, setMemberModal] = useState<{
    isVisible: boolean;
    selectedMember: { id: number; name: string } | null;
  }>(MEMBER_MODAL_DEFAULT_VALUE);

  const [editAccessModal, setEditAccessModal] = useState<{
    isVisible: boolean;
    selectedMember: { id: number; name: string } | null;
  }>(MEMBER_MODAL_DEFAULT_VALUE);

  const {
    data: members,
    mutate,
    isLoading,
    isValidating,
  } = useSWR(
    [`projects/${projectId}/members`, i18n.language, projectId],
    ([url]) => apiClient
      .get<{ data: ClientMemberResource[] }>(url)
      .then((response) => response?.response?.data),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  );

  const { data: pageData } = useSWR(
    [`projects/${projectId}/members/page-data`, i18n.language],
    ([url]) => apiClient
      .get<{ roles: RoleResource[], domains: Domain[] }>(url)
      .then(({ response }) => response),
    { revalidateOnFocus: false, revalidateOnReconnect: false, revalidateOnMount: true },
  );

  const roles = pageData?.roles;
  const domains = pageData?.domains;

  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);

  const {
    areYouStillHereModal,
    confirmPresenceHandler,
    valueOfTimer,
    numberOfTimer,
    getOutHandler,
    getEditAccess,
  } = useEditAccessHandler(clientId, projectId, storedProjectEditAccess, setStoredProjectEditAccess);

  const onDeleteMemberClick = (member: { id: number; name: string }) => {
    setMemberModal({ isVisible: true, selectedMember: member });
    setEditAccessModal({ isVisible: false, selectedMember: null });
  };
  const deleteMemberHandler = async (currentProjectId: string | undefined, memberId: number | undefined) => {
    if (typeof currentProjectId !== 'undefined' && typeof memberId !== 'undefined') {
      getEditAccess();
      await updateMemberRole(currentProjectId, memberId, NO_ROLE);
      mutate();
      setMemberModal(MEMBER_MODAL_DEFAULT_VALUE);
    }
  };

  const onSubmitNewUserForm = async ({ user, manageRole, selectedDomains }: AddTeamMemberFormType) => {
    if (projectId && user) {
      const domainsId = selectedDomains.map(selectedDomain => Number(selectedDomain.value));
      await updateMemberRole(projectId, user.id, Number(manageRole), domainsId);
      setIsNewMemberDrawerOpen(false);
      mutate();
      getEditAccess();
    }
  };

  const changeRoleInstantly = async (memberId: number, newRoleId: number, projId: string) => {
    const isNewRoleEdit = getLevelByRoleId(newRoleId, roles) === RoleLevel.EDIT;
    const allDomainsIds = isNewRoleEdit ? domains?.map(domain => domain.id) : [];
    await updateMemberRole(projId, memberId, newRoleId, allDomainsIds);
    mutate();
    getEditAccess();
  };

  const changeRoleAndRedirect = async () => {
    if (projectOwnerConfirmModal.selectedMember && projectId) {
      getEditAccess();
      await updateMemberRole(projectId, projectOwnerConfirmModal.selectedMember.id, projectOwnerConfirmModal.selectedMember.role);
      navigate('/');
    }
  };

  const onChangeRoleClick = async (memberId: number, newRole: SelectChangeEvent | RoleResource) => {
    const newRoleId = 'target' in newRole ? Number(newRole.target.value) : newRole.id;
    const isCurrentUserProjectOwner = (selectedUserId: number): boolean => {
      const selectedUser = members?.find(member => member.id === selectedUserId);
      const currentUserIsSelectedUser = selectedUser?.id === userData?.user.id;
      const isProjectOwner = selectedUser?.roles.some(role => role.scope === 'Project' && role.level === RoleLevel.PROJECT_OWNER) || false;
      return currentUserIsSelectedUser && isProjectOwner;
    };

    if (isCurrentUserProjectOwner(memberId)) {
      setEditAccessModal(MEMBER_MODAL_DEFAULT_VALUE);
      setMemberModal(MEMBER_MODAL_DEFAULT_VALUE);
      setProjectOwnerConfirmModal({ isVisible: true, selectedMember: { id: memberId, role: newRoleId } });
    } else {
      projectId && await changeRoleInstantly(memberId, newRoleId, projectId);
    }
  };

  const onOpenMobileOptions = (member: { id: number; name: string }) => {
    setEditAccessModal({
      isVisible: true,
      selectedMember: member,
    });
  };

  const onCloseDeleteModal = () => {
    setProjectOwnerConfirmModal(MEMBER_MODAL_DEFAULT_VALUE);
  };

  const updateDomains = async (memberId: number, newDomains: Array<Option>) => {
    const member = members?.find(m => m.id === memberId);
    const domainsIds = newDomains.map(domain => Number(domain.value));
    if (member) {
      await updateMemberRole(projectId!, memberId, member.roles.find(role => role.scope === 'Project')?.id || 0, domainsIds);
      mutate();
    }
  };

  return (
    <section>
      <SubHeader
        fallbackLink={`${prefix}/client/${clientId}/projects`}
        title={t('Manage access')}
      >
        <Button
          variant={ButtonVariants.SECONDARY}
          className={styles.newUserBtn}
          disabled={!storedProjectEditAccess?.isEditingAvailableShowOnUI}
          onClick={() => setIsNewMemberDrawerOpen(true)}
        >
          +
          <span>
            {' '}
            {t('Add new user')}
          </span>
        </Button>
      </SubHeader>
      <div className={classNames(styles.membersList, {
        [styles.membersList_loading]: members && (isLoading || isValidating),
        [styles.membersList_empty]: members && !members.length,
      })}
      >
        {domains && roles && members ? (
          members?.map((member) => (
            <ProjectTeamMember
              key={member.id}
              id={member.id}
              name={member.name}
              manageRole={member.roles.find((role) => role.scope === 'Project')}
              onDelete={onDeleteMemberClick}
              onChangeRole={(role) => onChangeRoleClick(
                member.id,
                role,
              )}
              onOpenMobileOptions={onOpenMobileOptions}
              roles={roles}
              t={t}
              domains={domains}
              selectedDomains={member.domains}
              updateDomains={updateDomains}
              isReadOnly={project.status?.state === StatusState.BLOCKED || !storedProjectEditAccess?.isEditingAvailableShowOnUI}
            />
          ))
        ) : (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {members && !members.length && !isLoading && !isValidating && (
          <div className={styles.membersList__empty}>
            <svg className={styles.membersList__empty__img}>
              <use
                xlinkHref={`${EmptyUserListSVG}#emptyUserListSVG`}
                href={`${EmptyUserListSVG}#emptyUserListSVG`}
              />
            </svg>
            <p className={styles.membersList__empty__text}>{t('You have not added any users to the project yet')}</p>
          </div>
        )}
      </div>

      {memberModal.isVisible && (
        <EntityBaseModal
          onClose={() => setMemberModal(MEMBER_MODAL_DEFAULT_VALUE)}
          onConfirmClick={() => deleteMemberHandler(projectId, memberModal.selectedMember?.id)}
          heading={t('Are you sure you want to delete the user from the project?')}
          confirmButtonText={t('Yes, delete')}
          isShowDeleteSVG
        >
          {memberModal?.selectedMember?.name && (
            <div className={styles.member__label}>
              <Avatar
                className={styles.member__label__icon}
                name={memberModal.selectedMember?.name}
              />
              <p>{memberModal.selectedMember?.name}</p>
            </div>
          )}
        </EntityBaseModal>
      )}
      <Drawer
        isOpen={isNewMemberDrawerOpen}
        setIsOpen={setIsNewMemberDrawerOpen}
        title={t('Add new user')}
        className={styles.drawer}
      >
        {projectId && roles && domains && (
          <AddTeamMemberForm
            {...{
              t,
              projectId,
              roles,
            }}
            onSubmit={onSubmitNewUserForm}
            i18nLanguage={i18n.language}
            domains={domains}
          />
        )}
      </Drawer>
      <Drawer
        isOpen={editAccessModal.isVisible}
        setIsOpen={(value: boolean) => setEditAccessModal({ isVisible: value, selectedMember: null })}
        title={t('Edit access')}
        className={styles.drawer}
      >
        {editAccessModal.selectedMember && !!domains && !!roles && (
          <EditMemberAccessForm
            onChangeRole={onChangeRoleClick}
            roles={roles}
            t={t}
            member={members?.find(member => member.id === editAccessModal.selectedMember?.id)!}
            onDeleteAccess={onDeleteMemberClick}
            domains={domains}
            updateDomains={updateDomains}
          />
        )}
      </Drawer>
      {areYouStillHereModal.isVisible && (
        <ConfirmPresenceModal
          {...{
            getOutHandler,
            confirmPresenceHandler,
            isShowGradientProgressBar: areYouStillHereModal.isVisible,
            valueOfTimer,
            numberOfTimer,
            t,
          }}
        />
      )}
      {projectOwnerConfirmModal.isVisible && projectOwnerConfirmModal.selectedMember && (
        <EntityBaseModal
          onClose={() => onCloseDeleteModal()}
          onConfirmClick={() => changeRoleAndRedirect()}
          heading={t('Would you like to change your access?')}
          confirmButtonText={t('Yes')}
          cancelButtonText={t('No')}
        >
          <p>
            {getLevelByRoleId(projectOwnerConfirmModal.selectedMember?.role, roles) === RoleLevel.EDIT
              ? t('With this change, you will not be able to manage the project team.')
              : t('With this change, you will no longer be able to access all functions.')}
          </p>
        </EntityBaseModal>
      )}
    </section>
  );
};

export default ProjectTeamManagment;
