import { ProjectJiraIntegrationStatus } from '../NewProject/NewProjectSummary/types';
import { CustomerResource } from '../Customers/types';
import { TemplateResource } from '../Templates/types';
import { Status } from '../../UIKit/StatusLabel/types.ts';
import { ClientMemberResource } from '../Users/UsersList/types.ts';

export enum ProjectProirityValue {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}
export type ProjectJiraIntegrationResource = {
  id: number;
  key: string;
  url: string;
  status: {
    value: ProjectJiraIntegrationStatus;
    caption: string;
    state: string;
  };
};

export type ProjectProirity = {
  value: ProjectProirityValue;
  caption: string;
};

export const ProjectStatusValue = {
  DRAFT: 0,
  ACTIVE: 1,
  FORKING: 10,
  RESTORING: 11,
  CALCULATING: 12,
  DUPLICATING: 13,
  SOURCE_DUPLICATING: 14,
} as const;

type ProjectStatus = Omit<Status, 'value'> & {
  value: typeof ProjectStatusValue[keyof typeof ProjectStatusValue]
};
export type ProjectBaseResource = {
  id: number;
  caption: string;
  description: string;
  status: ProjectStatus;
  priority?: ProjectProirity;
  begin: string;
  end: string;
  budget: number;
  step: number;
  permissions: string[];
  created_at: Date;
  schedule_edited: false;
};

export type ProjectResource = ProjectBaseResource & {
  customer: CustomerResource;
  template: TemplateResource;
  owners: ClientMemberResource[];
  answers: Record<string, number[]>;
  salesforce: string;
  jiraIntegration: ProjectJiraIntegrationResource | null;
  priority?: ProjectProirity;
  created_at: string;
};

export type VersionResource = {
  id: number;
  creator: string;
  number: number;
  created_at: string;
  parent: VersionResource;
};

export type ProjectEditAccess = {
  isViewMode: boolean,
  isEditingAvailable: boolean,
  isEditingAvailableShowOnUI: boolean,
  isEditingUnavailableModalVisible: boolean,
  userThatCurrentlyEditing: {
    userId: number | null,
    name: string | null,
    email: string | null,
  },
  requestedAt: string | null,
  projectId: number | null,
};

export type PollingSettings = {
  callback: ((response?: { data: ProjectResource }) => Promise<void>) | null,
  isTriggered: boolean,
};

export type UserThatCurrentlyEditingInfo = {
  email: string;
  full_name: string;
  requested_at: string;
  user_id: number;
};

export type ProjectRow = {
  id: number;
  caption: string;
  status: ProjectStatus;
  begin: string;
  end: string;
  budget: number;
  owners: string[];
  permissions: string[];
  withJiraIntegration: boolean;
};
