import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import SpinnerSVG from '../../../public/media/spinner-button.svg';
import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  TRANSPARENT = 'transparent', // usually for icon buttons
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ButtonSize {
  SMALL = 'small',
  DEFAULT = 'default',
}

type ConditionalProps = | {
  children?: never,
  icon: React.ReactNode
} | {
  children: React.ReactNode,
  icon?: React.ReactNode,
};

type CommonProps = {
  className?: string,
  variant?: ButtonVariants,
  iconPosition?: ButtonIconPosition,
  iconSize?: {
    width: number,
    height: number,
  } | number,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void,
  disabled?: boolean,
  type?: 'button' | 'submit' | 'reset',
  loading?: boolean,
  id?: string,
  size?: ButtonSize,
  uiMode?: boolean,
  title?: string,
};

type LinkProps = {
  link?: string,
  target?: string,
};

type ButtonProps = CommonProps & ConditionalProps & LinkProps;

const Button = ({
  className,
  children,
  variant = ButtonVariants.PRIMARY,
  icon,
  iconPosition = ButtonIconPosition.LEFT,
  iconSize,
  onClick,
  onMouseEnter,
  link = undefined,
  disabled = false,
  type = 'button',
  loading = false,
  target,
  id,
  size = ButtonSize.DEFAULT,
  uiMode = false,
  title,
}: ButtonProps) => {
  const classes = {
    [styles.primary]: variant === ButtonVariants.PRIMARY,
    [styles.secondary]: variant === ButtonVariants.SECONDARY,
    [styles.outlined]: variant === ButtonVariants.OUTLINED,
    [styles.transparent]: variant === ButtonVariants.TRANSPARENT,
    [styles.small]: size === ButtonSize.SMALL,
    [styles.withIcon]: icon || loading,
    [styles.withIcon_left]: iconPosition === ButtonIconPosition.LEFT,
    [styles.withIcon_center]: iconPosition === ButtonIconPosition.CENTER,
    [styles.disabled]: disabled,
    [styles.small]: size === ButtonSize.SMALL,
  };

  return (link ? (
    <Link
      to={link}
      className={classnames(className, styles.button, classes)}
      target={target}
      id={id}
    >
      {children}
      {(icon || loading) && (
        <div
          className={styles.icon}
          style={{
            width: typeof iconSize === 'number' ? iconSize : iconSize?.width,
            height: typeof iconSize === 'number' ? iconSize : iconSize?.height,
          }}
        >
          {/* TODO: add loading icon */}
          {loading ? (
            <svg className={styles.spinner}>
              <use
                xlinkHref={`${SpinnerSVG}#spinnerSVG`}
                href={`${SpinnerSVG}#spinnerSVG`}
              />
            </svg>
          ) : icon}
        </div>
      )}
    </Link>
  ) : (
    uiMode ? (
      <div
        className={classnames(className, styles.button, classes, {
          [styles.disabled]: disabled,
        })}
        id={id}
      >
        {children}
        {(icon || loading) && (
        <div
          className={styles.icon}
          style={{
            width: typeof iconSize === 'number' ? iconSize : iconSize?.width,
            height: typeof iconSize === 'number' ? iconSize : iconSize?.height,
          }}
        >
          {loading ? (
            <svg className={styles.spinner}>
              <use
                xlinkHref={`${SpinnerSVG}#spinnerSVG`}
                href={`${SpinnerSVG}#spinnerSVG`}
              />
            </svg>
          ) : icon}
        </div>
        )}
      </div>
    )
      : (
        <button // eslint-disable-next-line react/button-has-type
          type={type}
          className={classnames(className, styles.button, classes)}
          disabled={disabled}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          id={id}
          title={title}
        >
          {children}
          {(icon || loading) && (
          <div
            className={styles.icon}
            style={{
              width: typeof iconSize === 'number' ? iconSize : iconSize?.width,
              height: typeof iconSize === 'number' ? iconSize : iconSize?.height,
            }}
          >
            {loading ? (
              <svg className={styles.spinner}>
                <use
                  xlinkHref={`${SpinnerSVG}#spinnerSVG`}
                  href={`${SpinnerSVG}#spinnerSVG`}
                />
              </svg>
            ) : icon}
          </div>
          )}
        </button>
      )
  ));
};

export default Button;
