import classNames from 'classnames';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { useEffect, useState } from 'react';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';

import apiClient from '../../../../../apiClient';
import { TaskResource } from '../types';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { NewProjectContext } from '../../types';
import { useCustomTranslation } from '../../../../../useAppTranslate';

import styles from './SummaryDashboard.module.scss';

type RiskTask = TaskResource & {
  parentName: string
};

type TasksGroup = {
  name: string,
  tasks: RiskTask[]
};

const SummaryRiskTasks = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t, i18n } = useCustomTranslation();
  const [groupedTasks, setGroupedTasks] = useState<TasksGroup[]>([]);
  const { projectId, clientId } = useParams();

  const { data: tasks, isLoading, isValidating } = useSWR(
    [`projects/${projectId}/tasks?page=all`, i18n.language, project?.status?.state],
    async ([url]) => (project?.status?.state !== StatusState.PENDING ? apiClient
      .get<{ data: TaskResource[] }>(url)
    // data should be filtered by task_at_risk on backend
      .then(({ response }) => response.data.reduce((resultArray, currentTask, _, array) => {
        if (currentTask.task_at_risk) {
          const parentTask = array.find((task) => task.id === currentTask.parent_id);

          return [...resultArray, { ...currentTask, parentName: parentTask?.name || '' }];
        }

        return resultArray;
      }, [] as RiskTask[])) : null),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (tasks?.length) {
      const sortedTasks = tasks.sort((a, b) => (dayjs(a.start_date).isBefore(b.start_date) ? -1 : 1));

      const grouped = sortedTasks.reduce((result, currentTask) => {
        const existingGroupIndex = result.findIndex((group) => group.name === currentTask.parentName);

        if (existingGroupIndex !== -1) {
          result[existingGroupIndex].tasks.push(currentTask);

          return result;
        } else {
          return [...result, {
            name: currentTask.parentName,
            tasks: [currentTask],
          }];
        }
      }, [] as TasksGroup[]);
      setGroupedTasks(grouped);
    }
  }, [tasks]);

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.riskTasks, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Tasks at risk')}</h3>
            <Link
              to={`/d/client/${clientId}/project/${projectId}/summary/schedule`}
              type='button'
              className={classNames('link', styles.detailsButton)}
            >
              {t('See diagram')}
            </Link>
          </header>
          {
            groupedTasks?.length ? groupedTasks.map((group) => (
              <div
                key={group.name}
                className={styles.riskTasks__list}
              >
                <p className={styles.riskTasks__groupTitle}>{group.name}</p>
                <header className={classNames(styles.list__row, styles.list__header, styles.riskTasks__list__row)}>
                  <p className={styles.list__row_onLeft}>{t('Task')}</p>
                  <p>{t('Calc. duration')}</p>
                  <p>{t('Recomm. duration')}</p>
                </header>
                {group.tasks?.map((item) => (
                  <div
                    className={classNames(styles.riskTasks__list__row, styles.riskTasks__list__item)}
                    key={item.id}
                  >
                    <p className={styles.caption}>{item.name}</p>
                    <div className={classNames(styles.riskTasks__status, styles.riskTasks__status_risk)}>
                      {`${item.duration} ${
                        item.duration === 1 ? t('day') : t('days')
                      }`}
                    </div>
                    <div className={classNames(styles.riskTasks__status, styles.riskTasks__status_default)}>
                      {`${item.min_duration} ${item.min_duration === 1 ? t('day') : t('days')}`}
                    </div>
                  </div>
                ))}
              </div>
            )) : (
              <EmptyList title={t('No tasks at risk yet')} />
            )
          }
        </>
      )}
    </section>
  );
};

export default SummaryRiskTasks;
