import {
  FormControl, InputLabel, MenuItem, MenuProps, Select as MuiSelect, SelectChangeEvent,
} from '@mui/material';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import styles from './Select.module.scss';
import CheckboxItem from '../CheckboxItem/CheckboxItem';
import { useCustomTranslation } from '../../../useAppTranslate';

type MultiSelectAllProps =
  | {
    withSelectAll: true;
    onSelectAll: () => void;
    isAllSelected: boolean;
  } | {
    withSelectAll?: false;
    onSelectAll?: never;
    isAllSelected?: never;
  };

export type Option = { caption: string, value: string, disabled?: boolean, icon?: ReactElement };

type OptionProps = ({
  options: Array<Option>,
  children?: never,
} | {
  options?: never,
  children: React.ReactNode,
});

type SelectProps = {
  // The name of the select input, used for form handling.
  name: string,

  setValue: (e: SelectChangeEvent<string> | string[]) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  labelId: string,
  error?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  className?: string,
  tileClassName?: string,
  paperClassName?: string,
  type?: 'default' | 'tile',
  menuProps?: Partial<MenuProps>,
  renderValue?: (value: string | string[]) => React.ReactNode,
  onClose?: () => void,
} & OptionProps & (
  ({
    multiple: true,
    value: Array<string>,
    withCheckbox?: boolean,
  } & MultiSelectAllProps)
  | {
    value: string,
    withCheckbox?: never,
    multiple?: false | undefined,
  }) & ({
  label?: string,
} | {
  placeholder?: string,
});

const Select = ({
  value,
  setValue,
  onBlur,
  labelId,
  error,
  errorMessage,
  name,
  disabled,
  className,
  paperClassName,
  tileClassName,
  type = 'default',
  renderValue,
  onClose,
  menuProps,
  ...props
}: SelectProps) => {
  const { t } = useCustomTranslation('global');

  const handleChange = (event: SelectChangeEvent<string | Array<string>>) => {
    const { target } = event;

    if ('multiple' in props) {
      setValue?.(Array.isArray(target.value) ? target.value : target.value.split(','));
    } else {
      setValue?.(event as SelectChangeEvent<string>);
    }
  };

  const isMultiple = 'multiple' in props && props.multiple;
  const label = 'label' in props ? props.label : undefined;
  const placeholder = 'placeholder' in props ? props.placeholder : undefined;

  return (
    <FormControl
      className={classNames(className, {
        'field-error': error,
      })}
      classes={{
        root: classNames(styles.root, {
          [styles.tile]: type === 'tile',
          [tileClassName ?? '']: type === 'tile',
          [styles.withPlaceholder]: placeholder && !label,
        }),
      }}
    >
      <InputLabel
        className={styles.label}
        id={labelId}
      >
        {label || placeholder}
      </InputLabel>
      <MuiSelect
        classes={{
          select: styles.select,
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={(_props) => {
          const rotate = _props.className.toString().includes('iconOpen');
          return (
            <svg
              className={styles.arrow}
              style={{
                transform: rotate ? 'rotate(180deg)' : 'none',
              }}
            >
              <use
                xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                href={`${AngleDownSVG}#angleDownSVG`}
              />
            </svg>
          );
        }}
        labelId={labelId}
        value={value}
        label={label || placeholder}
        error={error}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        MenuProps={{
          PopoverClasses: {
            paper: classNames(paperClassName, styles.paperRoot, {
              [styles.tile__paper]: type === 'tile',
            }),
          },
          ...menuProps,
        }}
        multiple={props.multiple}
        renderValue={renderValue}
        placeholder={placeholder}
        onClose={onClose}
      >
        {isMultiple && props.withSelectAll && (
          <div
            // @ts-ignore hack to prevent MUI select click on this div
            disabled
            className={styles.selectAll}
          >
            <MenuItem
              onClick={e => {
                e.preventDefault();
                isMultiple && props.onSelectAll?.();
              }}
            >
              {isMultiple && props.withCheckbox ? (
                <CheckboxItem
                  fontSize={type === 'tile' ? 12 : 14}
                  value={props.isAllSelected}
                  label={t('All')}
                  indeterminate={'options' in props && (value.length > 0 && value.length < props?.options?.length!)}
                />
              ) : t('All')}
            </MenuItem>
          </div>
        )}
        {'children' in props ? props.children : props.options?.map(option => (
          <MenuItem
            key={option.caption}
            value={option.value}
            disabled={option?.disabled}
          >
            <span className={styles.option}>
              {isMultiple && props.withCheckbox && (
                <CheckboxItem
                  value={value.includes(option.value)}
                  labelClassName={styles.option__checkbox}
                />
              )}
              {option?.icon && <span className={styles.option__icon}>{option?.icon}</span>}
              <span>{option.caption}</span>
            </span>
          </MenuItem>
        ))}
      </MuiSelect>
      {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
    </FormControl>
  );
};

export default Select;
