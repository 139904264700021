import { Drawer } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import classNames from 'classnames';

import AvatarPlaceholderSVG from '../../public/media/avatar-placeholder.svg';
import CloseSVG from '../../public/media/close.svg';
import LogoSvg from '../../public/media/logo.svg';
import LogoutSVG from '../../public/media/logout.svg';
import AccountSettingsSVG from '../../public/media/account-settings-icon.svg';
import ManualSVG from '../../public/media/manual.svg';
import { userClientAtom } from '../../store/auth.ts';
import { clientPreviewIdAtom, customLogoAtom, previewLogoAtom } from '../../store/clientCI.ts';
import { UserProps } from '../pages/Login/user.props.ts';
import styles from './Header.module.scss';
import { ClientProps } from '../AdminSidebar/client.props.ts';
import AdminSidebar from '../AdminSidebar/AdminSidebar.tsx';
import SupportSVG from '../../public/media/support.svg';
import { HELP_DESK_URL } from '../../constants.ts';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

type HeaderMobileMenuProps = {
  isMenuOpened: boolean,
  setIsMenuOpened: (value: boolean) => void,
  setIsLogoutModalOpened: (value: boolean) => void,
  user?: UserProps['user'],
  showAdminSidebar?: boolean
};
const HeaderMobileMenu = ({
  isMenuOpened, setIsMenuOpened, setIsLogoutModalOpened, user, showAdminSidebar,
}: HeaderMobileMenuProps) => {
  const { t } = useCustomTranslation('global');
  const [isWithLeftMenu, setIsWithLeftMenu] = useState<boolean>(false);
  const loaderData = useLoaderData() as { clients?: ClientProps[] };
  const clients = loaderData?.clients ?? [];
  const clientPreviewId = useAtomValue(clientPreviewIdAtom);
  const previewLogo = useAtomValue(previewLogoAtom);
  const customLogo = useAtomValue(customLogoAtom);
  const currentClient = useAtomValue(userClientAtom);

  const toggleLeftMenu = () => {
    setIsWithLeftMenu(!isWithLeftMenu);
  };

  return (
    <Drawer
      anchor='left'
      open={isMenuOpened}
      onClose={() => setIsMenuOpened(false)}
      classes={{
        root: styles.mobileMenu,
        paper: classNames(styles.mobileMenu__paper, {
          [styles.withLeftMenu]: isWithLeftMenu,
        }),
      }}
    >
      {showAdminSidebar ? (
        <AdminSidebar
          clients={clients}
          isMobile
          toggleWithLeftMenu={toggleLeftMenu}
          closeMenu={() => setIsMenuOpened(false)}
        />
      ) : (
        <header className={styles.mobileMenu__header}>
          <Link
            to='/'
            className={styles.mobileMenu__logo}
          >
            <div
              className={styles.mobileMenu__logo__image}
            >
              {clientPreviewId && previewLogo?.logo.preview ? (
                <img
                  alt=''
                  src={previewLogo.logo.preview}
                />
              ) : customLogo?.logoUrl ? (
                <img
                  alt=''
                  src={customLogo.logoUrl}
                />
              ) : (
                <svg>
                  <use
                    xlinkHref={`${LogoSvg}#logoSVG`}
                    href={`${LogoSvg}#logoSVG`}
                  />
                </svg>
              )}

            </div>
            <p>{currentClient?.name?.toUpperCase() ?? 'ZEEMLESS'}</p>
          </Link>
          <button
            className={styles.menuButton_close}
            type='button'
            onClick={() => setIsMenuOpened(false)}
            aria-label='Close menu'
          >
            <svg>
              <use
                xlinkHref={`${CloseSVG}#closeSVG`}
                href={`${CloseSVG}#closeSVG`}
              />
            </svg>
          </button>
        </header>
      )}

      <div className={styles.mobileMenu__user}>
        <div className={styles.mobileMenu__user__icon}>
          <svg>
            <use
              xlinkHref={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
              href={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
            />
          </svg>
        </div>
        <div className={styles.mobileMenu__user__info}>
          <p className={styles.mobileMenu__user__name}>{user?.name}</p>
          <p className={styles.mobileMenu__user__email}>{user?.email}</p>
        </div>
      </div>
      {/* <button
        type='button'
        className={styles.mobileMenu__button}
        onClick={() => openTutorial(i18n.language)}
      >
        <svg>
          <use
            xlinkHref={`${QuestionCircleSVG}#questionCircleSVG`}
            href={`${QuestionCircleSVG}#questionCircleSVG`}
          />
        </svg>
        {t('User manual')}
      </button> */}
      <Link
        to='/user-manual'
        className={styles.mobileMenu__button}
      >
        <svg className={styles.userButton}>
          <use
            xlinkHref={`${ManualSVG}#manualSVG`}
            href={`${ManualSVG}#manualSVG`}
          />
        </svg>
        {t('Application manual')}
      </Link>
      <Link
        to='/d/account-settings'
        className={styles.mobileMenu__button}
      >
        <svg>
          <use
            xlinkHref={`${AccountSettingsSVG}#acountsettingsSVG`}
            href={`${AccountSettingsSVG}#accountsettingsSVG`}
          />
        </svg>
        {t('Account settings')}
      </Link>
      <a
        href={HELP_DESK_URL}
        target='_blank'
        className={styles.mobileMenu__button}
        rel='noreferrer'
      >
        <svg>
          <use
            xlinkHref={`${SupportSVG}#supportSVG`}
            href={`${SupportSVG}#supportSVG`}
          />
        </svg>
        {t('Help Desk')}
      </a>
      <button
        onClick={() => {
          setIsLogoutModalOpened(true);
          setIsMenuOpened(false);
        }}
        className={styles.mobileMenu__button}
        type='button'
      >
        <svg>
          <use
            xlinkHref={`${LogoutSVG}#logoutSVG`}
            href={`${LogoutSVG}#logoutSVG`}
          />
        </svg>
        {t('Log out')}
      </button>
    </Drawer>
  );
};

export default HeaderMobileMenu;
