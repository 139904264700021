import classNames from 'classnames';
import Modal from '../UIKit/Modal/Modal';
import Button, { ButtonVariants } from '../UIKit/Button/Button';
import GradientProgressBar from '../GradientProgressBar/GradientProgressBar';

import { useCustomTranslation } from '../../useAppTranslate';

import DeleteSVG from '../../public/media/delete-user.svg';

import styles from './EntityBaseModal.module.scss';

type EntityBaseModalProps = {
  onClose: () => void;
  onConfirmClick: () => void;
  heading: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  children?: React.ReactNode;
  isShowDeleteSVG?: boolean;
  isShowGradientProgressBar?: boolean;
  modalClassName?: string;
  modalContentClassName?: string;
  titlePosition?: TitlePosition;
  valueTimer?: number;
  numberOfSecondsTimer?: number;
};

export enum TitlePosition {
  LEFT = 'left',
  CENTER = 'center',
}

const EntityBaseModal = ({
  onClose,
  onConfirmClick,
  heading,
  confirmButtonText,
  cancelButtonText,
  children,
  isShowDeleteSVG = false,
  isShowGradientProgressBar = false,
  modalClassName,
  modalContentClassName,
  titlePosition = TitlePosition.CENTER,
  valueTimer = 0,
  numberOfSecondsTimer = 0,
}: EntityBaseModalProps) => {
  const { t } = useCustomTranslation('global');

  const cancelText = cancelButtonText || t('Cancel');

  const classesForTitle = {
    [styles.titleLeft]: titlePosition === TitlePosition.LEFT,
    [styles.titleCenter]: titlePosition === TitlePosition.CENTER,
  };

  return (
    <Modal
      closeModal={onClose}
      className={styles.modal}
      modalClassName={modalClassName}
    >
      <div className={classNames(styles.modal__content, modalContentClassName)}>
        {isShowDeleteSVG && (
        <img
          src={DeleteSVG}
          alt=''
        />
        )}
        {isShowGradientProgressBar && (
        <GradientProgressBar
          value={valueTimer}
          strokeWidth={13}
          className={styles.progressBar}
        >
          <p className={styles.numberOfSeсonds__number}>{numberOfSecondsTimer.toFixed(0)}</p>
          <p className={styles.numberOfSeсonds__text}>{numberOfSecondsTimer.toFixed(0) === '1' ? t('Second') : t('Seconds')}</p>
        </GradientProgressBar>
        )}
        <h4 className={classNames(classesForTitle)}>
          {heading}
        </h4>
        {children}
        <div className={styles.modal__btns}>
          <Button
            onClick={onClose}
            variant={ButtonVariants.SECONDARY}
          >
            {cancelText}
          </Button>
          <Button onClick={onConfirmClick}>{confirmButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EntityBaseModal;
