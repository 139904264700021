import React, { useState } from 'react';
import Popover, { PopoverPlacement } from '../../../UIKit/Popover/Popover.tsx';
import DotsSVG from '../../../../public/media/dots.svg';
import styles from './QualityGateOptionsDropdown.module.scss';
import DetailsSVG from '../../../../public/media/summary/parameters/details.svg';
import OptionsSVG from '../../../../public/media/summary/quality-gates/options.svg';
import EditSVG from '../../../../public/media/edit-icon.svg';
import DeleteSVG from '../../../../public/media/delete-icon.svg';
import { ProjectPermissions } from '../../Login/user.props.ts';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

type ListOptionsDropdownProps = {
  onShowDetailsClick?: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
  qualityGatepermissions: string[], // not project permisssion
  placement?: PopoverPlacement,
  isRescticted: boolean,
  isReadOnly: boolean
};

const QualityGateOptionsDropdown = ({
  onShowDetailsClick, onDeleteClick, onEditClick, qualityGatepermissions, placement, isRescticted, isReadOnly,
}: ListOptionsDropdownProps) => {
  const { t } = useCustomTranslation();
  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  if (!onShowDetailsClick && isReadOnly) return null;

  return (
    <Popover
      placement={placement ?? PopoverPlacement.CONTEXT_MENU}
      paperClassName={styles.paper}
      triggerButton={(
        <div className={styles.trigger}>
          {qualityGatepermissions.length === 0 ? (
            <svg>
              <use
                xlinkHref={`${OptionsSVG}#optionsSVG`}
                href={`${OptionsSVG}#optionsSVG`}
              />
            </svg>
          ) : (
            <svg>
              <use
                xlinkHref={`${DotsSVG}#dotsSVG`}
                href={`${DotsSVG}#dotsSVG`}
              />
            </svg>
          )}
        </div>
      )}
      closeCallback={closeCallback}
    >
      <div className={styles.menu}>
        {onShowDetailsClick && (
          <button
            type='button'
            onClick={onShowDetailsClick}
          >
            <svg>
              <use
                xlinkHref={`${DetailsSVG}#detailsSVG`}
                href={`${DetailsSVG}#detailsSVG`}
              />
            </svg>
            {t('Show details')}
          </button>
        )}
        {qualityGatepermissions.includes(ProjectPermissions.UPDATE) && !isRescticted && !isReadOnly && (
          <button
            type='button'
            onClick={() => {
              onEditClick();
              closePopover();
            }}
          >
            <svg>
              <use
                xlinkHref={`${EditSVG}#editSVG`}
                href={`${EditSVG}#editSVG`}
              />
            </svg>
            {t('Edit title and date')}
          </button>
        )}
        {qualityGatepermissions.includes(ProjectPermissions.DELETE) && !isRescticted && !isReadOnly && (
          <button
            type='button'
            onClick={() => {
              onDeleteClick();
              closePopover();
            }}
          >
            <svg>
              <use
                xlinkHref={`${DeleteSVG}#deleteSVG`}
                href={`${DeleteSVG}#deleteSVG`}
              />
            </svg>
            {t('Delete')}
          </button>
        )}
      </div>
    </Popover>
  );
};

export default QualityGateOptionsDropdown;
