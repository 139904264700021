import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';

import apiClient from '../../apiClient.ts';
import { useOutsideClick } from '../../useOutsideClick.ts';
import { languagesAtom, SimpleLocale } from '../../store/lang.ts';
import { UserResource } from '../pages/Users/UsersList/types.ts';
import { userAtom } from '../../store/auth.ts';
import { ReactComponent as AngleDownSVG } from '../../public/media/angle-down.svg';

import styles from './LangSelector.module.scss';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

const LangSelector = () => {
  const { i18n, t } = useCustomTranslation('global');
  const [userData, setUserData] = useAtom(userAtom);

  const languages = useAtomValue(languagesAtom);
  const [selected, setSelected] = useState<SimpleLocale | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpened(false));

  const onChange = async (lang: SimpleLocale) => {
    i18n.services.backendConnector.backend.options.customHeaders = { 'Accept-Language': lang.locale };
    i18n.changeLanguage(lang.locale);
    setSelected(lang);

    if (userData) {
      try {
        const { statusCode, response } = await apiClient.put<{
          data: UserResource,
          errors?: Record<string, string[]>
          message?: string
        }>('user', {
          body: JSON.stringify({ ...(userData?.user || {}), locale: lang.locale }),
        });

        if (statusCode === 200) {
          setUserData({ user: response.data, token: userData!.token });
        } else {
          throw new Error(response?.message ?? t('Failed to change user language'));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (languages) {
      const currLang = languages?.find((lang) => lang?.locale === i18n.language);
      currLang ? setSelected(currLang) : onChange(languages[0]);
    }
  }, [languages]);

  const onSelect = (lang: SimpleLocale) => {
    onChange(lang);
    setIsOpened(false);
  };

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.opened]: isOpened,
      })}
      ref={wrapperRef}
    >
      <div className={styles.selector}>
        <button
          type='button'
          onClick={() => setIsOpened(!isOpened)}
        >
          <div className={classnames(styles.selector__item, styles.selector__selected)}>
            {selected && (
              <>
                <img
                  src={selected.icon}
                  alt={selected.caption}
                  className={styles.flagIcon}
                />
                <span className={styles.selector__lang}>{selected.locale}</span>
                <AngleDownSVG className={styles.selector__icon} />
              </>
            )}
          </div>
        </button>
        {isOpened
          && languages?.map((language) => language.locale !== selected?.locale && (
            <button
              type='button'
              onClick={() => onSelect(language)}
              key={language.locale}
            >
              <div className={classnames(styles.selector__item, styles.selector__option)}>
                <img
                  className={styles.flagIcon}
                  src={language.icon}
                  alt={language.caption}
                />
                <span className={styles.selector__lang}>{language.locale}</span>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default LangSelector;
