import {
  GridSortItem,
} from '@mui/x-data-grid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { useAtomValue, useSetAtom } from 'jotai/index';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../apiClient.ts';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  DEFAULT_DATE_FORMAT, IS_DEV_ENV, IS_TRANSLATIONS_EDITABLE,
} from '../../constants.ts';
import DeleteIllustrationSVG from '../../public/media/delete-illustration.svg';
import PlusSVG from '../../public/media/plus.svg';
import SearchSVG from '../../public/media/search.svg';
import { userAtom } from '../../store/auth.ts';
import { languagesAtom, Locale } from '../../store/lang.ts';
import { NotificationStatus, notify } from '../../store/notifications.ts';
import { useDebounce } from '../../useDebounce.ts';
import usePage from '../../usePage.ts';
import Header from '../Header/Header.tsx';
import SubHeader from '../SubHeader/SubHeader.tsx';
import Button, { ButtonSize, ButtonVariants } from '../UIKit/Button/Button.tsx';
import Modal from '../UIKit/Modal/Modal.tsx';
import Popover, { PopoverPlacement } from '../UIKit/Popover/Popover.tsx';
import Table, { TableVariant } from '../UIKit/Table/Table.tsx';
import { addLocale, removeLocale } from './manageTranslationsAPI.ts';
import { TableColumnMenu } from './TableColumnMenu.tsx';
import { getTranslationsColumns } from './translationsColumns.tsx';
import { TranslationData, TranslationDataWithRows } from './types';
import styles from './MaintainTranslations.module.scss';
import SocketApi from '../../socketApiClient.ts';
import { CustomTrans, useCustomTranslation } from '../../useAppTranslate.tsx';
import AdminTools from './AdminTools.tsx';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const SearchFilterIcon = () => (
  <svg className={styles.searchFilterIcon}>
    <use
      xlinkHref={`${SearchSVG}#searchSVG`}
      href={`${SearchSVG}#searchSVG`}
    />
  </svg>
);

// force editable for dev env
const isReadOnly = !(IS_TRANSLATIONS_EDITABLE || IS_DEV_ENV);
const shouldShowImportButton = IS_DEV_ENV || !IS_TRANSLATIONS_EDITABLE;
const shouldShowExportButton = IS_DEV_ENV || IS_TRANSLATIONS_EDITABLE;

const MaintainTranslations = () => {
  const { t, i18n } = useCustomTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userData = useAtomValue(userAtom);
  const setLanguages = useSetAtom(languagesAtom);

  const LIMIT = 30;
  const { page, updatePage } = usePage(false);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState(searchParams.get('search') || '');
  const debouncedSearch = useDebounce<string>(search, 300);
  const defaultSortingSettings = searchParams.get('order_by') !== null && searchParams.get('sort_direction') !== null
    ? { order_by: searchParams.get('order_by') || '', direction: searchParams.get('sort_direction') || '' }
    : null;
  const [sortingSettings, setSortingSettings] = useState<{ order_by: string, direction: string } | null>(defaultSortingSettings);
  const [filterSettings, setFilterSettings] = useState<Record<string, any> | null>(null);
  const debouncedFilterSettings = useDebounce<Record<string, any> | null>(filterSettings, 300);
  const [isIntercactionBlocked, setIsIntercactionBlocked] = useState<boolean>(false);

  useEffect(() => {
    if (debouncedSearch || (debouncedFilterSettings && Object.values(debouncedFilterSettings).filter(v => v).length)) {
      updatePage(1);
      setSearchParams(prev => ({
        ...prev,
        page: 1,
      }), { replace: true });
    }
  }, [debouncedSearch, debouncedFilterSettings]);

  const {
    data,
    mutate: mutateTranslations,
    isLoading,
    isValidating,
  } = useSWR(
    ['maintain-translations/list', page, debouncedSearch, sortingSettings, debouncedFilterSettings, i18n.language],
    async (): Promise<TranslationDataWithRows> => {
      const params = new URLSearchParams({
        ...(page !== 1 ? { page: String(page) } : {}),
        per_page: String(LIMIT),
        ...(search.length > 0 ? { search } : {}),
        ...(sortingSettings || {}),
        filters: JSON.stringify(filterSettings),
      }).toString();
      const { response, statusCode } = await apiClient
        .get<TranslationData>(`maintain-translations/list?${params}&locale=en`);
      if (statusCode !== 200) {
        notify({ text: { body: response.message } });
      }
      const rows = [
        ([...response.active_translations_locales, ...response.inactive_translations_locales])
          .reduce<{ [locale: string]: string | null }>((acc, curr) => {
          acc[curr.locale] = curr.latest_update
            ? dayjs(`${curr.latest_update} UTC`).format(`${userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT} HH:mm:ss`) : '-';
          return { ...acc, id: crypto.randomUUID(), timestamp: 'true' };
        }, {}),
        ...(response.data || []),
      ];
      return { ...response, rows };
    },
    {
      keepPreviousData: true,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    data && setTotalPages(data.meta.last_page);
  }, [data]);

  useEffect(() => {
    data && setLanguages(data.active_translations_locales.filter(language => language.is_publish));
  }, [data?.active_translations_locales]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      setSearchParams(prev => {
        const queryParams = Object.fromEntries(prev.entries());
        if (debouncedSearch.length > 0) {
          queryParams.search = debouncedSearch;
          delete queryParams.page;
        } else {
          delete queryParams.search;
        }
        if (sortingSettings) {
          queryParams.order_by = sortingSettings.order_by;
          queryParams.sort_direction = sortingSettings.direction;
        } else {
          delete queryParams.order_by;
          delete queryParams.sort_direction;
        }
        return { ...queryParams };
      }, {
        replace: true,
      });
    } else {
      isFirstRender.current = false;
    }
  }, [debouncedSearch, sortingSettings]);

  const onSort = (model: GridSortItem | null) => {
    setSortingSettings(model ? { order_by: model.field, direction: model.sort! } : null);
  };

  const [removedLocale, setRemovedLocale] = useState<null | Locale>(null);
  const onTranslationRemove = (locale: Locale) => {
    setRemovedLocale(locale);
  };

  const [closePopover, setClosePopover] = useState<() => void>(() => {});

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  const updateLocalTranslation = (id: number, value: string, locale: string) => {
    mutateTranslations((prevData) => {
      if (prevData) {
        const updatedRows = prevData?.rows.map((row) => {
          if ('id' in row && String(row.id) === String(id)) {
            return { ...row, [locale]: value };
          }
          return row;
        });
        return { ...prevData, rows: updatedRows };
      }
    }, false);
  };

  const updateTranslationUpdateTimestamp = (value: { [locale: string]: string | null }) => {
    mutateTranslations((prevData) => {
      if (prevData) {
        const updatedRows = prevData?.rows.map((row) => {
          if ('timestamp' in row && row.timestamp === 'true') {
            const formattedDates = Object.fromEntries(Object.entries(value)
              .map(([loc, timestamp]) => (
                [loc, value ? dayjs(`${timestamp} UTC`).format(`${userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT} HH:mm:ss`) : '-'])));
            return { ...row, ...formattedDates, id: crypto.randomUUID() };
          }
          return row;
        });
        return { ...prevData, rows: updatedRows };
      }
    }, false);
  };

  // // ai progress checker via websocket
  useEffect(() => {
    let sockets: SocketApi[];
    if (data?.active_translations_locales) {
      sockets = data?.active_translations_locales?.map((locale) => new SocketApi(locale?.websocket_url));
      sockets?.forEach((socket, i) => {
        socket.on('ai-translation-generation-event', ({ succeeded }: { succeeded: boolean }) => {
          const caption = data?.active_translations_locales[i].caption;
          notify({
            text: {
              title: t('AI translation generation'),
              body: (
                <CustomTrans
                  defaults={
                    succeeded
                      ? 'AI translation generation for {{langCaption}} was successful.'
                      : 'AI translation generation for {{langCaption}} failed.'
                  }
                  values={{ langCaption: caption.toLowerCase() }}
                />
              ),
            },
            status: succeeded ? NotificationStatus.SUCCESS : NotificationStatus.ERROR,
          });
          succeeded && mutateTranslations();
        });
      });
    }
    () => {
      sockets?.forEach((socket) => socket.off());
    };
  }, [data]);
  // END ai progress checker via websocket

  const getTranslations = async () => {
    try {
      await apiClient.download(
        'maintain-translations/export',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'translations.xlsx',
      );
    } catch (error) {
      notify({ text: { body: error.message } });
    }
  };

  const postTranslations = async (file: File) => {
    try {
      setIsIntercactionBlocked(true);
      const formData = new FormData();
      formData.append('translation_file', file);
      const { statusCode, response } = await apiClient.post<{ message?: string }>('maintain-translations/import', { body: formData });
      if (statusCode === 204) {
        notify({ text: { title: t('Success'), body: '' }, status: NotificationStatus.SUCCESS });
        await mutateTranslations();
      } else {
        throw new Error(response?.message || 'Failed to upload translations');
      }
    } catch (error) {
      console.error(error);
      notify({ text: { body: error.message } });
    } finally {
      setIsIntercactionBlocked(false);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    const firstFile = files![0];
    if (firstFile) {
      return postTranslations(firstFile);
    }
  };

  return (
    <main className={styles.translations}>
      <Header />
      <SubHeader
        fallbackLink='/'
        title={t('Translations')}
      />
      <div className={styles.main}>
        <header className={styles.header}>
          <div className={styles.search}>
            <svg className={styles.search__searchIcon}>
              <use
                xlinkHref={`${SearchSVG}#searchSVG`}
                href={`${SearchSVG}#searchSVG`}
              />
            </svg>
            <input
              disabled={isIntercactionBlocked}
              value={search}
              type='text'
              onChange={e => setSearch(e.target.value)}
              className={classNames(styles.search__input, {})}
              placeholder={t('Search translations')}
            />
          </div>
          <div className={styles.header__controls}>
            <AdminTools
              shouldShowImportButton={shouldShowImportButton}
              shouldShowExportButton={shouldShowExportButton}
              importTranslations={handleUploadFile}
              exportTranslations={getTranslations}
            />
            {!isReadOnly && (
            <Popover
              placement={PopoverPlacement.CONTEXT_MENU}
              disabled={data?.inactive_translations_locales.length === 0}
              className={styles.header__controls__item}
              triggerButton={(
                <Button
                  variant={ButtonVariants.SECONDARY}
                  size={ButtonSize.SMALL}
                  className={styles.header__controls__item}
                  disabled={data?.inactive_translations_locales.length === 0}
                  icon={(
                    <svg>
                      <use
                        xlinkHref={`${PlusSVG}#plusSVG`}
                        href={`${PlusSVG}#plusSVG`}
                      />
                    </svg>
                  )}
                  iconSize={{ width: 12, height: 12 }}
                  uiMode
                >
                  {t('Add language')}
                </Button>
              )}
              closeCallback={closeCallback}
            >
              <div className={styles.localesList}>
                {data?.inactive_translations_locales.map(locale => (
                  <button
                    className={styles.localeItem}
                    type='button'
                    key={locale.locale}
                    onClick={async () => {
                      await addLocale({
                        localeId: locale.id,
                        t,
                      });
                      await mutateTranslations();
                      closePopover();
                    }}
                  >
                    <img
                      className={styles.flagIcon}
                      src={locale.icon}
                      alt={locale.caption}
                    />
                    {locale.caption}
                  </button>
                ))}
              </div>
            </Popover>
            )}
          </div>
        </header>
        <div className={styles.content}>
          <Table
            rows={data?.rows}
            isLoading={isLoading || isValidating || isIntercactionBlocked}
            onPageChange={updatePage}
            totalPages={totalPages}
            columns={getTranslationsColumns({
              t,
              locales: data?.active_translations_locales,
              page,
              limit: LIMIT,
              updateLocalTranslation,
              updateTranslationUpdateTimestamp,
              isReadOnly,
            })}
            page={page}
            customRowId='id'
            onSortModelChange={onSort}
            variant={TableVariant.SIMPLE}
            initSortModel={sortingSettings ? {
              field: sortingSettings.order_by,
              sort: sortingSettings.direction,
            } as GridSortItem : undefined}
            onFilterModelChange={setFilterSettings} // eslint-disable-next-line react/no-unstable-nested-components
            columnMenu={(props) => (
              <TableColumnMenu
                props={props}
                locale={data!.active_translations_locales.find(locale => locale.locale === props.colDef.field)!}
                onTranslationRemove={onTranslationRemove}
                mutateTranslations={mutateTranslations}
                isReadOnly={isReadOnly}
              />
            )}
            customFilter={{
              title: 'Search translation',
              icon: SearchFilterIcon,
            }}
          />
        </div>
      </div>
      {removedLocale && (
        <Modal
          closeModal={() => setRemovedLocale(null)}
          className={styles.deleteModal}
        >
          <svg className={styles.deleteModal__illustration}>
            <use
              xlinkHref={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
              href={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
            />
          </svg>
          <h3 className={styles.deleteModal__title}>
            {t('Are you sure you want to delete the “{{caption}}” translations option?', { caption: removedLocale.caption })}
          </h3>
          <footer className={styles.deleteModal__footer}>
            <Button
              onClick={() => setRemovedLocale(null)}
              variant={ButtonVariants.SECONDARY}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={async () => {
                await removeLocale({ t, localeId: removedLocale.id });
                setRemovedLocale(null);
                await mutateTranslations();
              }}
              variant={ButtonVariants.PRIMARY}
            >
              {t('Yes, delete')}
            </Button>
          </footer>
        </Modal>
      )}
    </main>
  );
};

export default MaintainTranslations;
