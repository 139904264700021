import classNames from 'classnames';
import React from 'react';
import styles from './Notification.module.scss';
import {
  Notification as NotificationType, NotificationStatus, removeNotification, NotificationOptionView, NotificationPopupIcon,
} from '../../../store/notifications.ts';
import CloseSVG from '../../../public/media/close.svg';
import CheckedSVG from '../../../public/media/checked.svg';
import ProcessSVG from '../../../public/media/process.svg';
import Button from '../Button/Button.tsx';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

type NotificationProps = NotificationType;
const Notification = (
  {
    status = NotificationStatus.ERROR,
    text: {
      title,
      body,
    },
    id,
    ...props
  }: NotificationProps,
) => {
  const { t } = useCustomTranslation('global');
  if (props.type === NotificationOptionView.ALERT) {
    return (
      <div className={classNames(styles.notification, {
        [styles.error]: status === NotificationStatus.ERROR,
        [styles.success]: status === NotificationStatus.SUCCESS,
      })}
      >
        <p className={styles.notificationText}>
          <span className={styles.title}>{title}</span>
          {body && <span className={styles.body}>{body}</span>}
        </p>
        <button
          aria-label={t('Dismiss notification')}
          type='button'
          className={styles.closeButton}
          onClick={() => removeNotification(id)}
        >
          <svg>
            <use
              xlinkHref={`${CloseSVG}#closeSVG`}
              href={`${CloseSVG}#closeSVG`}
            />
          </svg>
        </button>
      </div>
    );
  }
  if (props.type === NotificationOptionView.INFO_POPUP) {
    return (
      <div className={styles.infoPopup}>
        <div className={classNames(styles.icon, {
          [styles.icon_success]: props.icon === NotificationPopupIcon.SUCCESS,
          [styles.icon_pending]: props.icon === NotificationPopupIcon.PENDING,
        })}
        >
          {props.icon === NotificationPopupIcon.PENDING ? (
            <svg>
              <use
                xlinkHref={`${ProcessSVG}#processSVG`}
                href={`${ProcessSVG}#processSVG`}
              />
            </svg>
          ) : (
            <svg>
              <use
                xlinkHref={`${CheckedSVG}#checkedSVG`}
                href={`${CheckedSVG}#checkedSVG`}
              />
            </svg>
          )}
        </div>
        <p className={styles.text}>
          {title && <span className={styles.title}>{title}</span>}
          <span className={styles.body}>{body}</span>
        </p>
        {!props.withoutCloseButton && (
        <button
          aria-label={t('Dismiss notification')}
          type='button'
          className={styles.closeButton}
          onClick={() => removeNotification(id)}
        >
          <svg>
            <use
              xlinkHref={`${CloseSVG}#closeSVG`}
              href={`${CloseSVG}#closeSVG`}
            />
          </svg>
        </button>
        )}
      </div>
    );
  }
  if (props.type === NotificationOptionView.POPUP) {
    return (
      <div className={styles.popupWrapper}>
        <div className={styles.popup}>
          <button
            aria-label={t('Dismiss notification')}
            type='button'
            className={styles.popup__closeButton}
            onClick={() => removeNotification(id)}
          >
            <svg>
              <use
                xlinkHref={`${CloseSVG}#closeSVG`}
                href={`${CloseSVG}#closeSVG`}
              />
            </svg>
          </button>
          <div className={styles.popup__text}>
            <h4>{title}</h4>
            <p>{body}</p>
          </div>
          <Button
            className={styles.popup__okButton}
            onClick={() => removeNotification(id)}
          >
            {t('Ok, got it')}
          </Button>
        </div>
      </div>
    );
  }
};
export default Notification;
