import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './DragDropFileUploadArea.module.scss';
import ExcelSVG from '../../../public/media/excel-icon.svg';
import { useCustomTranslation } from '../../../useAppTranslate';

type DragDropFileUploadAreaProps = {
  handleDropFile: (e: React.DragEvent<HTMLDivElement>) => void;
  handleChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  touched: boolean;
  error?: string;
  value: File | null;
  className?: string;
};

const DragDropFileUploadArea = forwardRef<HTMLInputElement, DragDropFileUploadAreaProps>(({
  handleDropFile, handleChangeFile, touched, error, value, className,
}, ref) => {
  const { t } = useCustomTranslation('global');

  const handleDragEvent = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={classNames(styles.fileInputSection, className)}
      id='fileInputSection'
      onDrop={e => {
        handleDragEvent(e);
        handleDropFile(e);
      }}
      onDragOver={handleDragEvent}
      onDragEnter={handleDragEvent}
      onDragLeave={handleDragEvent}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label
        htmlFor='file'
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // @ts-ignore
            ref?.click?.();
          }
        }}
      >
        <svg className={styles.file__svg}>
          <use
            xlinkHref={`${ExcelSVG}#excelIcon`}
            href={`${ExcelSVG}#excelIcon`}
          />
        </svg>
        <span className={styles.fileInputSection__title}>{t('Upload Excel file')}</span>
        <span className={styles.fileInputSection__subtitle}>{t('Select file')}</span>
        {error && touched ? <span className={styles.fileInputSection__error}>{error}</span> : null}
        <span className={styles.fileInputSection__info}>
          {value ? (value as File).name : t('No file selected yet')}
        </span>
        <input
          ref={ref}
          id='file'
          name='file'
          type='file'
          onChange={handleChangeFile}
          hidden
        />
      </label>
    </div>
  );
});

export default DragDropFileUploadArea;
