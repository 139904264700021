import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import Button from '../UIKit/Button/Button';
import CheckboxItem from '../UIKit/CheckboxItem/CheckboxItem.tsx';
import Input from '../UIKit/Input/Input';

import { usePermissions } from '../../usePermission.ts';
import { ClientProps } from '../AdminSidebar/client.props.ts';
import { Permissions } from '../pages/Login/user.props.ts';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

import styles from './ClientForm.module.scss';

type ClientFormProps = {
  client?: ClientProps,
  submitForm: (values: Record<string, any>) => void,
  submitButtonText: string
};

const ClientForm = ({ client, submitForm, submitButtonText }: ClientFormProps) => {
  const { t } = useCustomTranslation('global');

  const {
    handleSubmit, values, handleChange, setFieldValue, handleBlur, touched, errors, setValues,
  } = useFormik({
    initialValues: {
      name: '',
      premium_license: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('Name is required')).max(100, t('Must be 100 characters at most')),
    }),
    onSubmit: submitForm,
  });

  useEffect(() => {
    if (!client) return;
    setValues({ name: client?.name ?? '', premium_license: client?.premium_license ?? false });
  }, [client]);

  const { hasGlobalPermission } = usePermissions();

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div>
        <Input
          value={values.name}
          setValue={handleChange}
          onBlur={handleBlur}
          id='name'
          label={t('Enter name')}
          name='name'
          error={!!(touched.name && errors.name)}
          errorMessage={errors.name}
        />
        {hasGlobalPermission(Permissions.ADMIN) && (
        <CheckboxItem
          labelClassName={styles.form__checkboxLabel}
          value={values.premium_license}
          onChange={() => setFieldValue('premium_license', !values.premium_license)}
          label={t('Premium license client')}
          name='premium_license'
        />
        )}
      </div>
      <div className={styles.form__footer}>
        <Button type='submit'>{submitButtonText}</Button>
      </div>
    </form>
  );
};

export default ClientForm;
