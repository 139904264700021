import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAtomValue } from 'jotai';
import React from 'react';
import classnames from 'classnames';
import { DEFAULT_DATE_FORMAT } from '../../../constants.ts';
import { userAtom } from '../../../store/auth.ts';
import { TaskResource } from '../../pages/NewProject/NewProjectSummary/types.ts';
import styles from '../ProjectTracking.module.scss';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

dayjs.extend(utc);
dayjs.extend(timezone);

export const StatusUpdateCell = ({ task, className }: { task: TaskResource, className?: string }) => {
  const { t } = useCustomTranslation();
  const userData = useAtomValue(userAtom);

  return task.last_history ? (
    <div className={classnames(styles.statusUpdate, className)}>
      <p className={styles.statusUpdate__author}>{task.last_history.data}</p>
      <p className={styles.statusUpdate__date}>
        {`${t('On')} ${dayjs.utc(task.last_history.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(
          `${userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT} | HH:mm`,
        )}`}
      </p>
    </div>
  ) : <div className={className} />;
};
