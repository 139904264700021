import React from 'react';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useResetAtom } from 'jotai/utils';
import { useMediaQuery } from '@mui/material';
import LogoSVG from '../../../public/media/logo-and-name-upper.svg';
import styles from './NotFound.module.scss';
import Button from '../../UIKit/Button/Button.tsx';
import NotFoundSVG from '../../../public/media/404.svg';
import apiClient from '../../../apiClient.ts';
import { clientPermissionsAtom, globalPermissionsAtom, userAtom } from '../../../store/auth.ts';
import { MOBILE_MEDIA_QUERY } from '../../../constants.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const NotFound = ({ withLogo }: { withLogo?: boolean }) => {
  const { t } = useCustomTranslation('global');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const resetUser = useResetAtom(userAtom);
  const resetGlobalPermissions = useResetAtom(globalPermissionsAtom);
  const resetClientPermissions = useResetAtom(clientPermissionsAtom);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  const handleLogout = async () => {
    try {
      const { statusCode } = await apiClient
        .post('auth/logout');

      if (statusCode === 200) {
        resetUser();
        resetGlobalPermissions();
        resetClientPermissions();

        return navigate('/login', { replace: true });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classNames(styles.wrapper, {
      [styles.withHeader]: !withLogo,
    })}
    >
      <div className={styles.notFound}>
        <div className={styles.header}>
          {withLogo && (
            <svg>
              <use
                xlinkHref={`${LogoSVG}#logoUpperSVG`}
                href={`${LogoSVG}#logoUpperSVG`}
              />
            </svg>
          )}
        </div>
        <div className={styles.body}>
          <svg>
            <use
              width={isMobileDevice ? '315px' : undefined}
              xlinkHref={`${NotFoundSVG}#notFoundSVG`}
              href={`${NotFoundSVG}#notFoundSVG`}
            />
          </svg>
          <p>{t('We could not find the page you were looking for.')}</p>
          {searchParams.has('logout') ? (
            <Button
              type='button'
              onClick={handleLogout}
            >
              {t('Logout')}
            </Button>
          ) : (
            <Button
              type='button'
              link='/'
            >
              {t('Back to home')}
            </Button>
          )}
        </div>
        <p className={styles.footer}>{t('ZEEMLESS. Copyright {{year}}.', { year: new Date().getFullYear() })}</p>
      </div>
    </div>
  );
};

export default NotFound;
