import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TFunction } from 'i18next';
import {
  ClientMemberResource, Domain, RoleLevel, RoleResource,
} from '../Users/UsersList/types';
import styles from './ProjectTeamManagment.module.scss';

import DeleteSVG from '../../../public/media/delete-icon.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import KeySVG from '../../../public/media/key.svg';
import ViewSVG from '../../../public/media/view.svg';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import Loader from '../../Loader/Loader';
import Select from '../../UIKit/Select/Select';
import { isAllDomainsSelected } from './ProjectTeamMember';

type EditMemberAccessFormProps = {
  onChangeRole: (memberId: number, newRole: RoleResource) => Promise<void>,
  onDeleteAccess: (member: { id: number; name: string }) => void,
  member: ClientMemberResource;
  t: TFunction<string, undefined>;
  roles: RoleResource[];
  domains: Domain[];
  updateDomains: (memberId: number, newDomains: Array<{ value: string, caption: string }>) => Promise<void>;
};

const icons: Record<string, { icon: string, id: string }> = {
  edit: {
    icon: EditSVG,
    id: 'editSVG',
  },
  view: {
    icon: ViewSVG,
    id: 'viewSVG',
  },
  key: {
    icon: KeySVG,
    id: 'keySVG',
  },
};

const EditMemberAccessForm = ({
  onChangeRole, t, roles, member, onDeleteAccess, domains, updateDomains,
}: EditMemberAccessFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [memberDomains, setMemberDomains] = useState(member.domains.map((domain) => ({
    value: String(domain.id),
    caption: domain.caption,
  })));

  const memberRole = useMemo<RoleResource | undefined>(
    () => member?.roles.find(role => role.scope === 'Project'),
    [member?.id, member?.roles],
  );

  useEffect(() => {
    setMemberDomains(member.domains.map((domain) => ({
      value: String(domain.id),
      caption: domain.caption,
    })));
  }, [memberRole?.id]);

  const changeRoleHandler = (role: RoleResource) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (member && role && e.currentTarget.checked) {
      setLoading(true);
      onChangeRole(member?.id, role).finally(() => {
        setLoading(false);
      });
    }
  };

  const updateDomainsHandler = (
    memberId: number,
    newDomains: Array<{
      value: string;
      caption: string;
    }>,
  ) => {
    setLoading(true);
    updateDomains(memberId, newDomains).finally(() => {
      setLoading(false);
    });
  };

  const deleteHandler = useCallback(() => {
    if (member) {
      onDeleteAccess(member);
    }
  }, [member]);

  const isSelectedAll = isAllDomainsSelected(domains, memberDomains);

  return (
    <section className={styles.editAccessModal}>
      <ul className={styles.rolesList}>
        {roles.map(role => (
          <li>
            <label
              htmlFor={`member-role-${role.id}`}
              className={styles.role}
            >
              <svg className={styles.role__icon}>
                <use
                  xlinkHref={`${role?.icon ? icons[role?.icon].icon : EditSVG}#${role?.icon ? icons?.[role?.icon]?.id : 'editSVG'}`}
                  href={`${role?.icon ? icons[role?.icon].icon : EditSVG}#${role?.icon ? icons?.[role?.icon]?.id : 'editSVG'}`}
                />
              </svg>
              <p className={styles.role__caption}>{role.caption}</p>
              <span className={styles.role__selectIndicator}>
                {memberRole?.id === role.id && (
                <span className={styles.role__selectIndicator__active} />
                )}
              </span>
              <input
                checked={memberRole?.id === role.id}
                type='checkbox'
                name='member-role'
                id={`member-role-${role.id}`}
                onChange={changeRoleHandler(role)}
                hidden
              />
            </label>
          </li>
        ))}
      </ul>
      <Select
        name='domains'
        labelId='domains'
        type='tile'
        label={t('None selected')}
        disabled={memberRole?.level !== RoleLevel.EDIT}
        value={memberDomains.map((domain) => domain.value)}
        options={domains.map((domain) => ({
          value: String(domain.id),
          caption: domain.caption,
        }))}
        renderValue={() => {
          if (memberDomains.length === 0) return t('Select domains');
          if (isSelectedAll) return t('All domains');
          return memberDomains.map((domain) => domain.caption).join(', ');
        }}
        setValue={(values) => {
          setMemberDomains((values as string[]).map((value) => {
            const domain = domains.find((d) => d.id === Number(value));
            return { caption: domain?.caption || '', value: String(domain?.id || '') };
          }));
        }}
        onSelectAll={() => {
          isSelectedAll ? setMemberDomains([])
            : setMemberDomains(domains.map((domain) => ({
              value: String(domain.id),
              caption: domain.caption,
            })));
        }}
        onClose={() => {
          updateDomainsHandler(member?.id, memberDomains);
        }}
        isAllSelected={isSelectedAll}
        multiple
        withCheckbox
        withSelectAll
      />
      <Button
        variant={ButtonVariants.SECONDARY}
        icon={(
          <svg>
            <use
              xlinkHref={`${DeleteSVG}#deleteSVG`}
              href={`${DeleteSVG}#deleteSVG`}
            />
          </svg>
              )}
        iconSize={{ width: 20, height: 20 }}
        className={styles.deleteButton}
        onClick={deleteHandler}
      >
        {t('Delete access')}
      </Button>
      {loading && (
        <div className={styles.editAccessModal__loader}>
          <Loader />
        </div>
      )}
    </section>
  );
};

export default EditMemberAccessForm;
