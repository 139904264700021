export enum StatusState {
  WARNING = 'warning',
  ERROR = 'error',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  FORKING = 'forking',
  DUPLICATING = 'duplicating',
  BLOCKED = 'blocked',
}

export type Status = {
  value?: number;
  caption: string;
  descroption?: string;
  icon?: string;
  state?: StatusState;
};
