import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import DeleteIllustrationSVG from '../../../../../../public/media/delete-illustration.svg';
import Button, { ButtonVariants } from '../../../../../UIKit/Button/Button.tsx';
import Modal from '../../../../../UIKit/Modal/Modal.tsx';
import { IProjectRisk } from '../types.ts';
import styles from './DeleteRiskModal.module.scss';
import apiClient from '../../../../../../apiClient.ts';
import { NotificationStatus, notify } from '../../../../../../store/notifications.ts';
import { useCustomTranslation } from '../../../../../../useAppTranslate.tsx';

type ModalProps = {
  closeModal: () => void,
  risk: IProjectRisk,
  onRiskDelete: (id: number) => void
};

const DeleteRiskModal = ({ closeModal, risk, onRiskDelete }: ModalProps) => {
  const { projectId } = useParams();
  const { t } = useCustomTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteRisk = async (): Promise<void> => {
    setIsDeleting(true);

    try {
      const { statusCode } = await apiClient.delete(`projects/${projectId}/risks/${risk.id}`);

      if (statusCode === 204) {
        onRiskDelete(risk.id);
        notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Risk deleted') } });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (e) {
      notify(e?.message ? { text: { body: e.message } } : {});
      console.error(e.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      className={styles.modal}
    >
      <div className={styles.modal__content}>
        <svg className={styles.modal__illustration}>
          <use
            xlinkHref={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
            href={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
          />
        </svg>
        <div>
          <h4>
            {t('Are you sure you want to remove the selected risk')}
            {' '}
            {`"${risk.caption}" ?`}
          </h4>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            type='button'
            onClick={closeModal}
            variant={ButtonVariants.SECONDARY}
          >
            {t('Cancel')}
          </Button>
          <Button
            type='button'
            loading={isDeleting}
            disabled={isDeleting}
            onClick={deleteRisk}
          >
            {t('Yes, delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRiskModal;
