import { API_URL, LS_LANG_KEY } from './constants';
import { getAuthorizeToken } from './utils';
import { AuthStorageKey } from './store/auth.ts';

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

const DOWNLOAD_FORMATS = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XML: 'application/xml',
} as const;

type DownloadFormatsType = typeof DOWNLOAD_FORMATS[keyof typeof DOWNLOAD_FORMATS];

export type ApiClient = {
  headers: Record<string, string>;
  authorize: boolean;
  /** @deprecated */
  withAuthToken(): ApiClient;
  withoutAuth(): ApiClient;
  request<ResponseType = Response>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
    params?: RequestInit
  ): Promise<{ statusCode: number; response: ResponseType }>;
  get<ResponseType = Response>(url: string, params?: RequestInit): Promise<{ statusCode: number; response: ResponseType }>;
  post<ResponseType = Response>(url: string, params?: RequestInit): Promise<{ statusCode: number; response: ResponseType }>;
  put<ResponseType = Response>(url: string, params?: RequestInit): Promise<{ statusCode: number; response: ResponseType }>;
  patch<ResponseType = Response>(url: string, params?: RequestInit): Promise<{ statusCode: number; response: ResponseType }>;
  delete<ResponseType = Response>(url: string, params?: RequestInit): Promise<{ statusCode: number; response: ResponseType }>;
  download(url: string, accept: DownloadFormatsType | (string & {}), fallbackName: string, method?: 'get' | 'post'): Promise<void>;
};

type ClientItemResource = {
  id: number;
  name: string;
};

const updateUserPermissions = async (client: ApiClient) => {
  const getUserClient = async () => {
    const { response } = await client.get<{ data: ClientItemResource[] }>('clients');
    return response.data?.[0];
  };
  const updateClientPermissions = async (clientId: number) => {
    const {
      response: { permissions },
    } = await client.get<{ data: ClientItemResource; permissions: string[] }>(`clients/${clientId}`);
    localStorage.setItem(AuthStorageKey.CLIENT_PERMISSIONS, JSON.stringify(permissions));
    return permissions;
  };

  const updateGlobalPermissions = async () => {
    const {
      response: { data: permissions },
    } = await client.get<{ data: string[] }>('user/permissions');
    localStorage.setItem(AuthStorageKey.GLOBAL_PERMISSIONS, JSON.stringify(permissions));
    return permissions;
  };
  const userClient = await getUserClient();
  await updateClientPermissions(userClient?.id);
  await updateGlobalPermissions();
  window.location.href = '/access-denied';
};

function getUrlWithBase(url: string) {
  return `${API_URL}/${url}`;
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const authorizationErrorHandler = () => {
  localStorage.removeItem(AuthStorageKey.USER_DATA);
  localStorage.removeItem(AuthStorageKey.GLOBAL_PERMISSIONS);
  localStorage.removeItem(AuthStorageKey.CLIENT_PERMISSIONS);

  window.location.href = '/login';
  throw new Error('Unauthorized');
};

const apiClient: ApiClient = {
  headers: { ...defaultHeaders },
  authorize: true,
  withAuthToken() {
    const client = Object.create(this);
    client.authorize = true;
    return client;
  },

  withoutAuth() {
    const client = Object.create(this);
    client.authorize = false;
    return client;
  },

  async request<ResponseType = Response>(url: string, method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH', params?: RequestInit) {
    const headers = { ...this.headers };
    if (params?.body instanceof FormData) {
      delete headers['Content-Type'];
    }

    if (this.authorize) {
      const authorizeToken = getAuthorizeToken();
      if (authorizeToken) {
        headers.Authorization = `Bearer ${authorizeToken}`;
      }
    }

    const acceptedLanguage = localStorage.getItem(LS_LANG_KEY);
    if (acceptedLanguage) {
      headers['Accept-Language'] = acceptedLanguage;
    }
    const response = await fetch(getUrlWithBase(url), {
      method,
      ...params,
      headers: {
        ...headers,
        ...params?.headers,
      },
    });

    if (response.status === 401 && this.authorize) {
      authorizationErrorHandler();
    }

    if (response.status === 403) {
      await updateUserPermissions(apiClient);
    }

    if (response.status === 404) {
      window.location.href = '/not-found';
    }

    const isJson = response.headers.get('content-type')?.startsWith('application/json');

    return { statusCode: response.status, response: (isJson ? await response.json() : response) as ResponseType };
  },

  async get<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'GET', params);
  },
  async delete<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'DELETE', params);
  },
  async post<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'POST', params);
  },
  async put<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'PUT', params);
  },
  async patch<ResponseType = Response>(url: string, params?: RequestInit) {
    return this.request<ResponseType>(url, 'PATCH', params);
  },
  async download(url: string, accept: string, fallbackName: string, method: 'get' | 'post' = 'get') {
    const { response, statusCode } = await apiClient[method]<{ message: string; blob:() => Promise<Blob>; headers: Headers }>(url, {
      headers: { accept },
    });
    if (statusCode === 200 && response?.headers) {
      const contentDisposition = response?.headers.get('content-disposition');
      let filename = fallbackName;

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+?)"?(\?.+)?$/);
        if (match && match[1]) {
          // eslint-disable-next-line prefer-destructuring
          filename = match[1];
        }
      }

      const blob = await response.blob();
      const a = document.createElement('a');
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    } else {
      throw new Error(response?.message);
    }
  },
};

export default apiClient;
