import { TFunction } from 'i18next';

import { useEffect, useState } from 'react';

import { SelectChangeEvent, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { Domain, RoleLevel, RoleResource } from '../Users/UsersList/types';
import { HIGHEST_ROLE_LEVEL } from '../../../constants';

import Avatar from '../../ColoredAvatar/ColoredAvatar';
import DeleteSVG from '../../../public/media/delete-icon.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import KeySVG from '../../../public/media/key.svg';
import ViewSVG from '../../../public/media/view.svg';
import DotsSVG from '../../../public/media/dots.svg';

import styles from './ProjectTeamManagment.module.scss';
import Button, { ButtonIconPosition, ButtonVariants } from '../../UIKit/Button/Button';
import Select, { Option } from '../../UIKit/Select/Select';
import { notify } from '../../../store/notifications';

const icons: Record<string, { icon: string, id: string }> = {
  edit: {
    icon: EditSVG,
    id: 'editSVG',
  },
  view: {
    icon: ViewSVG,
    id: 'viewSVG',
  },
  key: {
    icon: KeySVG,
    id: 'keySVG',
  },
};

type ProjectTeamMemberProps = {
  id: number;
  name: string;
  manageRole?: RoleResource;
  onDelete: ({ id, name }: { id: number; name: string }) => void;
  roles: RoleResource[];
  onChangeRole: (role: SelectChangeEvent) => void;
  onOpenMobileOptions: ({ id, name }: { id: number; name: string }) => void;
  t: TFunction<'translation', undefined>;
  domains: Domain[];
  selectedDomains: Domain[];
  updateDomains: (memberId: number, newDomains: Array<Option>) => Promise<void>;
  isReadOnly?: boolean;
};

const SelectorOption = ({ role, t }: { role: RoleResource, t: TFunction<'translation', undefined> }) => (
  <div className={styles.selectorItem}>
    <svg className={styles.selectorItem_icon}>
      <use
        xlinkHref={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
        href={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
      />
    </svg>
    <span>{role?.caption || t('Please select')}</span>
  </div>
);

export const isAllDomainsSelected = (domains: Domain[], selectedDomains: Option[]) => {
  const formattedDomains = domains.map((domain) => String(domain.id));
  const formattedSelectedDomains = selectedDomains.map((domain) => String(domain.value));
  return formattedDomains.every(e => formattedSelectedDomains.includes(e));
};

const ProjectTeamMember = ({
  name, manageRole, onDelete, roles, id, onChangeRole, t, onOpenMobileOptions, domains, selectedDomains, updateDomains,
  isReadOnly,
}: ProjectTeamMemberProps) => {
  const [memberDomains, setMemberDomains] = useState(selectedDomains.map((domain) => ({
    value: String(domain.id),
    caption: domain.caption,
  })));

  useEffect(() => {
    setMemberDomains(selectedDomains.map((domain) => ({
      value: String(domain.id),
      caption: domain.caption,
    })));
  }, [manageRole]);

  const isSelectedAll = isAllDomainsSelected(domains, memberDomains);

  const validateDomains = (userId: number, userDomains: Option[]) => {
    switch (true) {
      case userDomains.length === 0:
        notify({ text: { body: t('Please select at least one domain') } });
        setMemberDomains(selectedDomains.map((domain) => ({
          value: String(domain.id),
          caption: domain.caption,
        })));
        break;
      default:
        updateDomains(userId, userDomains);
        break;
    }
  };

  return (
    <div className={styles.member}>
      <Avatar
        className={styles.member__avatar}
        name={name}
      />
      <p className={styles.member__name}>
        {name}
        {manageRole && (
        <span className={styles.member__role}>
          <svg className={styles.member__role_icon}>
            <use
              xlinkHref={
                `${manageRole.icon ? icons[manageRole.icon].icon : EditSVG}#${manageRole.icon ? icons?.[manageRole.icon]?.id : 'editSVG'}`
              }
              href={
                `${manageRole.icon ? icons[manageRole.icon].icon : EditSVG}#${manageRole.icon ? icons?.[manageRole.icon]?.id : 'editSVG'}`
              }
            />
          </svg>
          <span>{manageRole?.caption || ''}</span>
        </span>
        )}
      </p>
      {manageRole?.level === HIGHEST_ROLE_LEVEL && (
      <p className={styles.member__pmLabel}>
        <svg className={styles.member__pmLabel_icon}>
          <use
            xlinkHref={`${KeySVG}#keySVG`}
            href={`${KeySVG}#keySVG`}
          />
        </svg>
        <span>{t('Project owner')}</span>
      </p>
      )}
      <div className={styles.memberSettings}>
        <Tooltip
          placement='top'
          title={manageRole?.level === RoleLevel.EDIT
            ? t('Deselecting any of the domains will restrict edit rights. deselcted domains can be viewed, not edited, by the user.')
            : null}
        >
          <span>
            <Select
              name='domains'
              labelId='domains'
              type='tile'
              label={t('None selected')}
              className={styles.member__selector}
              paperClassName={styles.member__selectorPaper}
              disabled={manageRole?.level !== RoleLevel.EDIT || isReadOnly}
              value={memberDomains.map((domain) => domain.value)}
              options={domains.map((domain) => ({
                value: String(domain.id),
                caption: domain.caption,
              }))}
              renderValue={() => {
                if (memberDomains.length === 0) return t('Select domains');
                if (isSelectedAll) return t('All domains');
                return memberDomains.map((domain) => domain.caption).join(', ');
              }}
              setValue={(values) => {
                setMemberDomains((values as string[]).map((value) => {
                  const domain = domains.find((d) => d.id === Number(value));
                  return { caption: domain?.caption || '', value: String(domain?.id || '') };
                }));
              }}
              onSelectAll={() => {
                isSelectedAll ? setMemberDomains([])
                  : setMemberDomains(domains.map((domain) => ({
                    value: String(domain.id),
                    caption: domain.caption,
                  })));
              }}
              onClose={() => {
                validateDomains(id, memberDomains);
              }}
              isAllSelected={isSelectedAll}
              multiple
              withCheckbox
              withSelectAll
            />

          </span>
        </Tooltip>
        <Select
          name='role'
          labelId='role'
          type='tile'
          label={t('Select role')}
          className={styles.member__selector}
          disabled={isReadOnly}
          options={roles.map((role) => ({
            value: String(role.id),
            caption: role.caption,
            icon: (
              <svg className={styles.selectorItem_icon}>
                <use
                  xlinkHref={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
                  href={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
                />
              </svg>),
          }))}
          value={manageRole?.id.toString() ?? ''}
          setValue={e => !Array.isArray(e) && onChangeRole(e)}
          renderValue={() => {
            if (manageRole) {
              return (
                <SelectorOption
                  role={manageRole}
                  t={t}
                />
              );
            }
          }}
        />
      </div>
      <button
        className={classNames(styles.member__removeBtn, { [styles.member__removeBtn_disabled]: isReadOnly })}
        type='button'
        aria-label={t('remove member')}
        disabled={isReadOnly}
        onClick={() => onDelete({ id, name })}
      >
        <svg className={styles.member__removeBtn_icon}>
          <use
            xlinkHref={`${DeleteSVG}#deleteSVG`}
            href={`${DeleteSVG}#deleteSVG`}
          />
        </svg>
      </button>
      <Button
        variant={ButtonVariants.SECONDARY}
        className={styles.member__optionsBtn}
        type='button'
        onClick={() => onOpenMobileOptions({ id, name })}
        icon={(
          <svg className={styles.member__optionsBtn_icon}>
            <use
              xlinkHref={`${DotsSVG}#dotsSVG`}
              href={`${DotsSVG}#dotsSVG`}
            />
          </svg>
        )}
        iconSize={{ width: 16, height: 16 }}
        iconPosition={ButtonIconPosition.CENTER}
      />
    </div>
  );
};

export default ProjectTeamMember;
