import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import useSWR from 'swr';
import { useState } from 'react';
import { TFunction } from 'i18next';
import Autocomplete from '../../UIKit/Autocomplete/Autocomplete';
import {
  ClientMemberResource, Domain, RoleLevel, RoleResource,
} from '../Users/UsersList/types';
import apiClient from '../../../apiClient';
import { useDebounce } from '../../../useDebounce';
import styles from './ProjectTeamManagment.module.scss';

import Select from '../../UIKit/Select/Select';
import Button from '../../UIKit/Button/Button';

type MultiSelectValue = {
  value: string;
  caption: string;
};

export type AddTeamMemberFormType = {
  user: ClientMemberResource | null,
  manageRole: string | null,
  selectedDomains: Array<{ value: string, caption: string }>,
};

const formInitialValues = (domains: Domain[]): AddTeamMemberFormType => ({
  user: null,
  manageRole: null,
  selectedDomains: domains.map((domain) => ({ value: String(domain.id), caption: domain.caption })),
});

const getLevelByRoleId = (id: number, roles?: RoleResource[]): number | undefined => roles?.find(role => role.id === id)?.level;

const isAllDomainsSelected = (domains: Array<Domain>, selectedDomains: Array<MultiSelectValue>) => {
  const formattedDomains = domains?.map((domain) => String(domain.id));
  const formattedSelectedDomains = selectedDomains?.map((domain) => String(domain.value));
  return formattedDomains.every(e => formattedSelectedDomains.includes(e));
};

type AddTeamMemberFormProps = {
  onSubmit: (values: AddTeamMemberFormType) => void;
  t: TFunction<string, undefined>;
  roles: RoleResource[];
  projectId: string;
  i18nLanguage: string;
  domains: Domain[];
};

const AddTeamMemberForm = ({
  onSubmit, t, roles, projectId, i18nLanguage, domains,
}: AddTeamMemberFormProps) => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleBlur,
    touched,
    errors,
  } = useFormik<FormikValues>({
    initialValues: formInitialValues(domains),
    validationSchema: Yup.object({
      user: Yup.object<ClientMemberResource>().required(t('User is required')),
      manageRole: Yup.string().required(t('Role is required')),
      selectedDomains: Yup.array().of(Yup.object({ value: Yup.string(), caption: Yup.string() })),
    }),
    onSubmit: (formikValues) => onSubmit(formikValues as AddTeamMemberFormType),
  });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 300);

  const {
    data: users,
  } = useSWR([`projects/${projectId}/members/available`, debouncedSearch, i18nLanguage], async ([url, searchValue]) => {
    const params = searchValue ? new URLSearchParams({ search: searchValue }).toString() : '';

    const { response } = await apiClient
      .get<{ data: ClientMemberResource[] }>(url + (params ? `?${params}` : ''));

    return response.data;
  }, { keepPreviousData: true });

  const isSelectedAll = isAllDomainsSelected(domains, values.selectedDomains);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div
        className={styles.form__section}
      >
        <Autocomplete
          label={t('Select user')}
          selected={values.user}
          classNames={{
            input: styles.form__autocomplete,
            label: styles.form__autocomplete__label,
            label_active: styles.form__autocomplete__label_active,
          }}
          onChange={(user) => setFieldValue('user', user)}
          options={users?.length ? users.map(({ name, id }) => (
            {
              icon: <div className={styles.form__autocomplete__icon}>{name[0]}</div>, id, value: name, title: `ID #${id}`,
            }
          )) : []}
          customSearch={setSearch}
          error={!!(touched.user && errors.user)}
          errorMessage={typeof errors.user === 'string' ? errors.user : undefined}
        />
        <Select
          label={t('Select user role')}
          options={roles?.map(role => ({ caption: role.caption, value: role.id.toString() })) ?? []}
          value={values.manageRole}
          setValue={handleChange}
          onBlur={handleBlur}
          labelId='manageRole'
          name='manageRole'
          error={!!(touched.manageRole && errors.manageRole)}
          errorMessage={typeof errors.manageRole === 'string' ? errors.manageRole : undefined}
          onClose={() => {
            setFieldValue('selectedDomains', domains.map((domain) => ({
              value: String(domain.id),
              caption: domain.caption,
            })));
          }}
        />
        <Select
          name='accessibleDomains'
          labelId='accessibleDomains'
          label={t('Select accessible domains')}
          disabled={getLevelByRoleId(Number(values.manageRole), roles) !== RoleLevel.EDIT}
          value={values.selectedDomains.map((domain: MultiSelectValue) => domain.value)}
          options={domains.map((domain) => ({
            value: String(domain.id),
            caption: domain.caption,
          }))}
          renderValue={() => {
            if (values.selectedDomains.length === 0) return t('None selected');
            if (isSelectedAll) return t('All domains');
            return values.selectedDomains.map((domain: MultiSelectValue) => domain.caption).join(', ');
          }}
          setValue={(prevValue) => {
            if (Array.isArray(prevValue)) {
              setFieldValue('selectedDomains', (prevValue).map((value) => {
                const domain = domains.find((d) => d.id === Number(value));
                return { caption: domain?.caption || '', value: String(domain?.id || '') };
              }));
            }
          }}
          onSelectAll={() => {
            isSelectedAll ? setFieldValue('selectedDomains', [])
              : setFieldValue('selectedDomains', domains.map((domain) => ({
                value: String(domain.id),
                caption: domain.caption,
              })));
          }}
          paperClassName={styles.form__select}
          isAllSelected={isSelectedAll}
          multiple
          withCheckbox
          withSelectAll
        />
      </div>
      <footer className={styles.form__footer}>
        <Button
          type='submit'
          className={styles.form__footer__button}
          loading={isSubmitting}
        >
          {t('Add new user')}
        </Button>
      </footer>
    </form>
  );
};

export default AddTeamMemberForm;
