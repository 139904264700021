import classNames from 'classnames';
import React from 'react';
import { Drawer as MuiDrawer } from '@mui/material';

import Loader from '../../Loader/Loader';

import { useCustomTranslation } from '../../../useAppTranslate';

import CloseSVG from '../../../public/media/close.svg';
import OopsSVG from '../../../public/media/oops.svg';

import styles from './Drawer.module.scss';

type DrawerProps = {
  trigger?: React.ReactNode,
  children: React.ReactNode,
  className?: string,
  contentClassName?: string,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  title?: string,
  subTitle?: string,
  withHeader?: boolean,
  loading?: boolean,
  error?: boolean,
};
// TODO: Consider passing title as ReactNode instead of title and subtitle
const Drawer = ({
  trigger, isOpen, setIsOpen, children, className, contentClassName, title, subTitle, withHeader = true, loading = false, error = false,
}: DrawerProps) => {
  const IS_NOT_LOADING_OR_ERROR = !loading && !error;
  const { t } = useCustomTranslation('global');

  return (
    <div className={styles.drawer}>
      {trigger}
      <MuiDrawer
        anchor='right'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{
          root: styles.drawer,
          paper: classNames(styles.paper, className),
        }}
      >
        {withHeader && (
          <header>
            <button
              type='button'
              className={styles.paper__close}
              onClick={() => setIsOpen(false)}
              aria-label='Close drawer'
            >
              <svg>
                <use
                  xlinkHref={`${CloseSVG}#closeSVG`}
                  href={`${CloseSVG}#closeSVG`}
                />
              </svg>
            </button>
            {IS_NOT_LOADING_OR_ERROR && (
              <div className={styles.paper__title}>
                <h3>{title}</h3>
                {subTitle && <p>{subTitle}</p>}
              </div>
            )}
          </header>
        )}
        {IS_NOT_LOADING_OR_ERROR && (
          <div className={classNames(styles.drawer__content, contentClassName)}>
            {children}
          </div>
        )}
        {loading && (
          <Loader className={styles.isAbsoluteLoader} />
        )}
        {error && (
          <div className={styles.drawer__error}>
            <svg>
              <use
                width='268px'
                xlinkHref={`${OopsSVG}#oopsSVG`}
                href={`${OopsSVG}#oopsSVG`}
              />
            </svg>
            <h3>{t('Something went wrong...')}</h3>
            <p>{t('An error occurred and we were unable to load the data. Please try again.')}</p>
          </div>
        )}
      </MuiDrawer>
    </div>
  );
};

export default Drawer;
