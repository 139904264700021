import { Params, redirect } from 'react-router-dom';
import { getProjectDetails } from './new-project.loader.ts';
import { checkPermissionsLoader } from './check-permissions.loader.ts';
import { Permissions, ProjectPermissions } from '../components/pages/Login/user.props';

export const projectWithPermissionLoader = async ({ params, platformPermission, projectPermission }:
{ params: Params<string>, platformPermission?: Permissions, projectPermission?: ProjectPermissions }) => {
  const result = await checkPermissionsLoader(params, platformPermission);
  if (result === null) {
    let project = null;

    if (params?.clientId && params?.projectId) {
      project = await getProjectDetails(params.clientId, params.projectId);
    }

    if (projectPermission && !project?.permissions.includes(projectPermission)) {
      return redirect('/access-denied');
    }

    return { project };
  } else {
    return result;
  }
};
