import React, { useState } from 'react';
import { TFunction } from 'i18next';
import classNames from 'classnames';
import Tabs from '../../../../UIKit/Tabs/Tabs';
import styles from './SummaryDashboard.module.scss';
import SummaryRiskTasks from './SummaryRiskTasks';
import SummaryGeneralProjectRisk from './SummaryGeneralProjectRisk';
import { useCustomTranslation } from '../../../../../useAppTranslate';

const tabs = (t: TFunction<'translation', undefined>) => [
  {
    id: 0,
    button: {
      title: (
        <div className={styles.tab}>
          <p>{t('Tasks at risk')}</p>
        </div>
      ),
    },
    content: SummaryRiskTasks,
  },
  {
    id: 1,
    button: {
      title: (
        <div className={styles.tab}>
          <p>{t('General project risks')}</p>
        </div>
      ),
    },
    content: SummaryGeneralProjectRisk,
  },
];

const SummaryRisks = () => {
  const { t } = useCustomTranslation();
  const [activeTab, setActiveTab] = useState(tabs(t)[0].id);

  return (
    <section className={classNames(styles.section, styles.summaryRisks)}>
      <Tabs
        buttonsClassName={styles.tabs}
        contentClassName={styles.tabsContent}
        value={activeTab}
        setValue={setActiveTab}
        tabs={tabs(t).map((tab) => ({
          ...tab,
          content: typeof tab.content === 'function' ? tab.content() : tab.content,
        }))}
      />
    </section>
  );
};

export default SummaryRisks;
