import React, { useRef, useState } from 'react';

import Button, { ButtonSize, ButtonVariants } from '../UIKit/Button/Button';
import { useCustomTranslation } from '../../useAppTranslate';

import styles from './MaintainTranslations.module.scss';

type AdminToolsProps = {
  shouldShowImportButton: boolean;
  shouldShowExportButton: boolean;
  importTranslations: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  exportTranslations: () => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AdminTools = ({
  shouldShowImportButton, shouldShowExportButton, importTranslations, exportTranslations,
}: AdminToolsProps) => {
  const { t } = useCustomTranslation();
  const [isExportPending, setIsExportPending] = useState(false);
  const [isImportPending, setIsImportPending] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const onExportTranslationsClick = async () => {
    setIsExportPending(true);
    await exportTranslations();
    setIsExportPending(false);
  };

  const onImportTranslationsClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsImportPending(true);
    await importTranslations(e);
    setIsImportPending(false);
  };

  return (
    <>
      {shouldShowExportButton && (
      <Button
        variant={ButtonVariants.SECONDARY}
        size={ButtonSize.SMALL}
        onClick={onExportTranslationsClick}
        loading={isExportPending}
        disabled={isExportPending}
        className={styles.header__controls__item}
      >
        {t('Export translations')}
      </Button>
      )}
      {shouldShowImportButton && (
      <label
        htmlFor='file'
        className={styles.header__controls__item}
      >
        <Button
          variant={ButtonVariants.SECONDARY}
          size={ButtonSize.SMALL}
          loading={isImportPending}
          disabled={isImportPending}
          onClick={handleButtonClick}
        >
          {t('Import translations')}
        </Button>
        <input
          ref={fileInputRef}
          id='file'
          name='file'
          type='file'
          onChange={onImportTranslationsClick}
          onClick={(e) => {
            // @ts-ignore
            e.currentTarget.value = null;
          }}
          disabled={isImportPending}
          hidden
        />
      </label>
      )}
    </>
  );
};

export default AdminTools;
