import React, {
  Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import { FormikValues, useFormik } from 'formik';
import useSWR from 'swr';
import {
  useBeforeUnload, useNavigate, useOutletContext, useParams,
} from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import Select from '../../../UIKit/Select/Select';
import styles from './NewProjectQuestions.module.scss';
import Button from '../../../UIKit/Button/Button.tsx';
import Loader from '../../../Loader/Loader.tsx';
import apiClient from '../../../../apiClient.ts';
import { ProjectResource } from '../../Projects/types.ts';
import { ProjectPermissions } from '../../Login/user.props.ts';
import { StatusState } from '../../../UIKit/StatusLabel/types.ts';
import { scrollIntoView } from '../../../../utils';
import OptionsGroup from '../../../UIKit/OptionsGroup/OptionsGroup.tsx';
import { QuestionFlags, Questions } from '../../../UserForm/types';
import { NewProjectContext, Redirect } from '../types.ts';
import { useAutoSave } from '../useAutoSave.ts';
import Modal from '../../../UIKit/Modal/Modal.tsx';
import QuestionnaireSVG from '../../../../public/media/questionnaire.svg';
import AiSVG from '../../../../public/media/ai.svg';
import AILabel from '../AILabel.tsx';

import { eventEmitter, EventType } from '../../../../eventEmitter.ts';
import { notify } from '../../../../store/notifications.ts';
import { projectEditAccess } from '../../../../store/project.ts';
import { useDebounce } from '../../../../useDebounce.ts';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

type StructureQuestion = Questions & { sub?: StructureQuestion[] };

type PrefillingAnswers = {
  [key: string]: number[];
};

let initialFormValues: FormikValues = {};

const NewProjectQuestions = () => {
  const {
    projectAtom,
    progressAtoms,
    setPollingSettings,
    setInteractionBlocker,
    getEditAccess,
    isAiLoadingScreenVisible,
    setIsAiLoadingScreenVisible,
  } = useOutletContext<NewProjectContext>();
  const [project, setProject] = useAtom(projectAtom);
  const { clientId, projectId } = useParams();
  const { t, i18n } = useCustomTranslation();
  const navigate = useNavigate();
  const storedProjectEditAccess = useAtomValue(projectEditAccess);
  const isAbleToEdit = project?.permissions?.includes(ProjectPermissions.UPDATE);

  const params = useParams();

  let isReadOnly: boolean;
  if (params?.projectId) {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes?.(ProjectPermissions.VIEW))
    || !storedProjectEditAccess?.isEditingAvailableShowOnUI
    || project?.status?.state === StatusState.BLOCKED
    || project?.permissions?.includes?.(ProjectPermissions.PARTIAL_UPDATE);
  } else {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes?.(ProjectPermissions.VIEW))
    || project?.status?.state === StatusState.BLOCKED
    || project?.permissions?.includes?.(ProjectPermissions.PARTIAL_UPDATE);
  }
  const updateProgress = useSetAtom(progressAtoms.questions);

  const [structuredQuestions, setStructuredQuestions] = useState<StructureQuestion[]>([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState<Yup.Schema>(Yup.object({}));
  const [projectState, setProjectState] = useState<StatusState>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState<Record<string, boolean>>({});
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [wasChangesMade, setWasChangesMade] = useState(false);
  const [isProjectProcessing, setIsProjectProcessing] = useState(false);

  useBeforeUnload(useCallback((e) => {
    if (wasChangesMade) {
      e.preventDefault();
    }
  }, [wasChangesMade]));

  const {
    data,
    isLoading: areQuestionsLoading,
    mutate,
  } = useSWR(
    [`clients/${clientId}/projects/${projectId}/questions`, i18n.language],
    ([url]) => apiClient
      .get<{ data: Questions[], answers: Record<number, number>[] }>(url).then(({ response }) => response),
    { keepPreviousData: true, revalidateOnFocus: false },
  );

  const questions = data?.data;
  const answers = data?.answers;

  const {
    values, errors, touched, setTouched, validateForm, setValues, setFieldValue, handleChange, handleBlur,
  }: FormikValues = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
    },
    enableReinitialize: true,
  });

  useEffect(
    // eslint-disable for better readability
    // eslint-disable-next-line arrow-body-style
    () => {
      return () => {
        setPollingSettings(prev => ({ ...prev, callback: null }));
      };
    },
    [],
  );

  useEffect(() => {
    if (!project) return;

    setIsProjectLoaded(false);

    const { permissions, status } = project;

    const hasRequiredPermissions = permissions.includes(ProjectPermissions.UPDATE)
      || permissions.includes(ProjectPermissions.VIEW);
    if (!hasRequiredPermissions) {
      return navigate(Redirect.MANAGE);
    }

    setProjectState(status.state);
  }, [project]);

  useEffect(() => {
    if (!questions) return;

    const initial = questions.reduce((acc: Record<string, any>, { id }) => {
      acc[id] = answers?.[id] ? `${answers?.[id][0]}` : '';
      return acc;
    }, {});
    setInitialValues(initial);

    const mandatory: Record<string, boolean> = {};
    const newSchema = Yup.object().shape(
      questions.reduce((acc: Record<string, any>, { id, depends }) => {
        if (!depends?.length) {
          acc[id] = Yup.string().required(t('Answer is required'));
          mandatory[id] = true;
        } else {
          depends.forEach(depend => {
            acc[id] = Yup.string()
              .when(`${depend.question}`, ([other], schema) => {
                if (other === `${depend.answer}`) {
                  mandatory[id] = true;
                  return schema.required(t('Answer is required'));
                }

                mandatory[id] = false;
                return schema;
              });
          });
        }
        return acc;
      }, {}),
    );

    setValidationSchema((prev) => prev.concat(newSchema));
    setMandatoryFields(mandatory);

    const structured: StructureQuestion[] = [];

    questions.forEach((question, index) => {
      // All questions have categories, but on page only unique categories should be displayed
      // Category is set to empty string to skip it while rendering
      const category = question.category === questions[index - 1]?.category ? '' : question.category;
      const updated = { ...question, sub: [], category };

      if (question.depends?.length) {
        const related = structured.find(q => q.id === question.depends[0].question);
        Array.isArray(related?.sub) && related.sub.push({ ...question, category });
      } else {
        structured.push(updated);
      }
    });

    setStructuredQuestions(structured);
    initialFormValues = { ...initial };
    setIsProjectLoaded(true);
  }, [questions, answers]);

  useEffect(() => {
    if (!isProjectLoaded) return;

    const valuesObj = JSON.stringify(values);
    const initialValuesObj = JSON.stringify(initialFormValues);

    setWasChangesMade(valuesObj !== initialValuesObj);
  }, [values, isProjectLoaded]);

  const debouncedChanges = useDebounce<boolean>(values, 1500);

  useEffect(() => {
    wasChangesMade && !storedProjectEditAccess?.isViewMode && getEditAccess();
  }, [wasChangesMade, debouncedChanges, storedProjectEditAccess?.isViewMode]);

  useEffect(() => {
    if (!Object.keys(mandatoryFields).length) return;

    const value = Object.keys(mandatoryFields).reduce((acc, key) => {
      if (mandatoryFields[key]) {
        acc.total += 1;
      }

      if (values[key]) {
        acc.filled += 1;
      }

      return acc;
    }, { total: 0, filled: 0 });

    updateProgress((value.filled / value.total) * 100);
  }, [values, mandatoryFields]);

  const isFieldVisible = (id: number | string): boolean => {
    const field = questions?.find(question => question.id === id);
    if (!field) return false;
    if (!field.depends) return true;

    for (let i = 0; i < field.depends.length; i += 1) {
      const { question, answer } = field.depends[i];
      if ((values as Record<string, any>)[question] === `${answer}`) {
        return true;
      }
    }

    return false;
  };

  const isFormValid = async (submitValues: typeof values) => {
    const touchedFields = Object.keys(submitValues)
      .reduce((fields: Record<string, boolean>, key) => {
        fields[key] = true;
        return fields;
      }, {});
    await setTouched(touchedFields, false);
    const validateErrors = await validateForm();

    return Object.keys(validateErrors).length === 0;
  };

  const submitQuestions = async (submitValues: typeof values, isDraft = false, isAutoSave?: boolean) => {
    setIsProjectProcessing(true);
    const submitAnswers = Object.entries(submitValues).reduce((acc: Record<string, number[]>, [key, value]) => {
      if (value) acc[key] = [+value];
      return acc;
    }, {});

    const { statusCode, response } = await apiClient
      .post<{
      data: ProjectResource,
      errors?: Record<string, string[]>
    }>(`clients/${clientId}/projects/${projectId}/questions?draft=${isDraft}`, {
      body: JSON.stringify({ answers: submitAnswers }),
    });

    if (statusCode !== 200) {
      throw new Error('Something went wrong');
    }

    setProject({ ...project, ...response.data });

    setInteractionBlocker(prev => ({ ...prev, callback: isAutoSave ? null : () => setIsModalVisible(true) }));
    setPollingSettings(() => ({
      callback: async (pollingResponse) => {
        const state = pollingResponse?.data?.status?.state;
        await mutate();
        setProjectState(state);
        setIsProjectProcessing(false);
      },
      isTriggered: true,
    }));

    return response.data;
  };

  const formikValuesRef = useRef();
  const wasChangesMadeRef = useRef<boolean>();

  formikValuesRef.current = values;
  wasChangesMadeRef.current = wasChangesMade;

  useAutoSave({
    prefix: 'project',
    key: `questions-${projectId}`,
    isActive: isProjectLoaded && projectState !== StatusState.PENDING && !isProjectProcessing,
    callback: async () => {
      if (!wasChangesMade || isProjectProcessing) return;

      try {
        if (projectState === StatusState.WARNING) {
          await submitQuestions(formikValuesRef.current, true, true);
        } else {
          const isValid = await isFormValid(formikValuesRef.current);

          if (!isValid || projectState === StatusState.PENDING) {
            scrollIntoView('field-error');
            return;
          }
          const submittedProject = await submitQuestions(formikValuesRef.current, false, true);
          setProject({ ...project, ...submittedProject });
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    if (areQuestionsLoading || !isProjectLoaded || project?.status?.state === StatusState.PENDING) return;
    eventEmitter.removeAllListeners(EventType.LOGOUT);

    eventEmitter.once(EventType.LOGOUT, async ({ callback }: { callback: () => Promise<void> }) => {
      if (wasChangesMadeRef.current && projectId && !isProjectProcessing) {
        try {
          if (projectState === StatusState.WARNING) {
            await submitQuestions(formikValuesRef.current, true, true);
          } else {
            const isValid = await isFormValid(formikValuesRef.current);

            if (isValid) {
              await submitQuestions(formikValuesRef.current, false, true);
            }
          }
        } catch (e) {
          notify();
        }
      }
      await callback();
    });

    return () => {
      eventEmitter.removeAllListeners(EventType.LOGOUT);
    };
  }, [areQuestionsLoading, isProjectLoaded, isProjectProcessing]);

  const saveProject = async (submitValues: typeof values) => {
    const isValid = await isFormValid(submitValues);

    if (!isValid) {
      scrollIntoView('field-error');
      return;
    }

    try {
      await submitQuestions(submitValues);
    } catch (e) {
      console.error(e);
    }
  };

  const continueToNextStep = async () => {
    try {
      const submittedProject = await submitQuestions({});
      return navigate(`/d/client/${clientId}/project/${submittedProject.id}/deliverables`);
    } catch (e) {
      console.error(e);
    }
  };

  const [generatedAnswersByAI, setGeneratedAnswersByAI] = useState<{ [key: string]: string } | null>(null);

  const resetAIAnswer = (questionId: number) => {
    setGeneratedAnswersByAI((prev) => {
      if (prev) {
        return { ...prev, [questionId]: null };
      }
      return null;
    });
  };

  const renderQuestion = (question: StructureQuestion, position: string, isSubQuestion: boolean = false): any => {
    if (!isFieldVisible(question.id)) return null;

    return (
      <Fragment key={question.id}>
        {question.category && (<h4>{question.category}</h4>)}

        <div
          className={classNames(styles.question, {
            [styles.subQuestion]: isSubQuestion,
            [styles.hasCategory]: question.category,
          })}
        >
          <div className={styles.question__title}>
            <span>{position}</span>
            <p>{question.caption}</p>
          </div>

          <AILabel
            className={styles.question__iaLabel}
            t={t}
            isLabelVisible={Boolean(generatedAnswersByAI?.[question.id])}
          >
            {question.flags & QuestionFlags.YES_NO ? (
              <OptionsGroup
                value={values[question.id] ?? ''}
                setValue={(value) => {
                  setFieldValue(`${question.id}`, value);
                  resetAIAnswer(question.id);
                }}
                options={question.answers.map(answer => ({ caption: answer.caption, value: `${answer.id}` }))}
                error={!!(errors[question.id] && touched[question.id])}
                disabled={isReadOnly}
              />
            ) : (
              <Select
                label={t('Select')}
                options={question.answers.map(answer => ({ caption: answer.caption, value: `${answer.id}` }))}
                value={values[question.id] ?? ''}
                setValue={(e) => {
                  handleChange(e);
                  resetAIAnswer(question.id);
                }}
                onBlur={handleBlur}
                labelId={`${question.id}`}
                name={`${question.id}`}
                className={styles.question__select}
                error={!!(errors[question.id] && touched[question.id])}
                type='tile'
                disabled={isReadOnly}
              />
            )}
          </AILabel>
        </div>
        {question?.sub?.length ? question.sub.map((q, index) => renderQuestion(q, `${position}.${index + 1}`, true)) : null}
      </Fragment>
    );
  };

  const nextStepDisabled = isReadOnly && project.step <= 2;

  const getAiHelp = async () => {
    try {
      setIsAiLoadingScreenVisible(true);
      const { statusCode, response } = await apiClient
        .get(`projects/${projectId}/prefilling-questions-second-step`);

      if (statusCode === 200) {
        const prefillingAnswers = response as unknown as PrefillingAnswers;
        const formattedPrefillingAnswers: { [key: string]: string } = {};
        Object.keys((response)).forEach(key => {
          formattedPrefillingAnswers[key] = String(prefillingAnswers[key][0]);
        });
        setGeneratedAnswersByAI(formattedPrefillingAnswers);
        await setValues(formattedPrefillingAnswers);
      } else {
        throw new Error(t('Requests have exceeded the token rate limit. Please retry after a few minutes.'));
      }
    } catch (e) {
      notify({ text: { body: e?.message } });
      console.error(e);
    } finally {
      setIsAiLoadingScreenVisible(false);
    }
  };

  if (isAiLoadingScreenVisible) {
    return (
      <div className={styles.aiLoadingWrapper}>
        <Loader />
        <p className={styles.aiLoadingWrapper__text}>
          {t('Pro tip: You can change the color of ZEEMLESS\' appearance to fit your corporate identity.')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerArea}>
        <h3>{t('Project specific questions')}</h3>
        {Boolean(structuredQuestions?.length) && (
          <Button
            type='button'
            icon={(
              <svg>
                <use
                  xlinkHref={`${AiSVG}#aiSVG`}
                  href={`${AiSVG}#aiSVG`}
                />
              </svg>
            )}
            iconSize={{ width: 12, height: 12 }}
            className={styles.headerArea__button}
            onClick={getAiHelp}
            disabled={(areQuestionsLoading || projectState === StatusState.PENDING) || !isProjectLoaded || isReadOnly}
          >
            {t('Get AI Help')}
          </Button>
        )}
      </div>
      {(areQuestionsLoading || projectState === StatusState.PENDING) || !isProjectLoaded ? (
        <div className={styles.loader}>
          <Loader size={32} />
        </div>
      ) : !structuredQuestions?.length ? (
        <div className={styles.notQuestions}>
          <p>{t('No questions to be answered')}</p>
          <Button
            onClick={continueToNextStep}
            disabled={nextStepDisabled}
          >
            {t('Continue')}
          </Button>
        </div>
      ) : (
        <form className={styles.form}>
          {structuredQuestions?.length ? structuredQuestions.map((question, index) => renderQuestion(question, `${index + 1}`)) : null}
          <div className={styles.form__buttons}>
            <Button
              type='button'
              disabled={nextStepDisabled || isProjectProcessing}
              loading={isProjectProcessing}
              onClick={() => (isReadOnly ? navigate(`/d/client/${clientId}/project/${projectId}/deliverables`) : saveProject(values))}
            >
              {t('Next')}
            </Button>
          </div>
        </form>
      )}
      {isModalVisible && (
        <Modal
          closeModal={() => setIsModalVisible(!isModalVisible)}
          rootClassName={styles.modal}
        >
          <div className={styles.modal__content}>
            <svg>
              <use
                xlinkHref={`${QuestionnaireSVG}#questionnaireSVG`}
                href={`${QuestionnaireSVG}#questionnaireSVG`}
              />
            </svg>
            <div>
              <h4>{t('Great!')}</h4>
              <p>{t('Your questionnaire has been successfully sent. Click here to make subsequent changes.')}</p>
            </div>
            <Button
              onClick={() => navigate(`/d/client/${clientId}/project/${projectId}/deliverables`)}
            >
              {t('Configuration')}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NewProjectQuestions;
