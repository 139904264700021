import { ProjectResource, ProjectRow, ProjectStatusValue } from './types';

const redirectToSummaryStatuses: typeof ProjectStatusValue[keyof typeof ProjectStatusValue][] = [
  ProjectStatusValue.ACTIVE,
  ProjectStatusValue.SOURCE_DUPLICATING,
];

export default function getProjectLink(
  data: (ProjectResource[] | ProjectRow[]),
  projectId: number,
  clientId?: string,
  selectedClientId?: string,
) {
  const project = data?.find((item) => item.id === Number(projectId));
  const toSummary = project?.status?.value && redirectToSummaryStatuses.includes(project?.status?.value);
  return `/d/client/${clientId ?? selectedClientId}/project/${projectId}${toSummary ? '/summary' : ''}`;
}
