import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QuestionCircleSVG from '../../../public/media/question-cirlce.svg';
import ManualSVG from '../../../public/media/manual.svg';
import styles from './SupportResourcesMenu.module.scss';
import SupportSVG from '../../../public/media/support.svg';
import Popover, { PopoverPlacement } from '../../UIKit/Popover/Popover';
import { HELP_DESK_URL } from '../../../constants';
import { useCustomTranslation } from '../../../useAppTranslate';

const SupportResourcesMenu = () => {
  const { t } = useCustomTranslation('global');
  const [, setClosePopover] = useState<() => void>(() => {
  });
  const closeCallback = (func: (e: Event) => void) => {
    setClosePopover(() => func);
  };
  return (
    <Popover
      paperClassName={styles.popover}
      placement={PopoverPlacement.CONTEXT_MENU}
      triggerButton={(
        <div>
          <svg className={styles.menuButton}>
            <use
              xlinkHref={`${QuestionCircleSVG}#questionCircleSVG`}
              href={`${QuestionCircleSVG}#questionCircleSVG`}
            />
          </svg>
        </div>
      )}
      closeCallback={closeCallback}
    >
      <div className={styles.menu}>
        {/* this buttons is in design but should be implemented in future */}
        {/* <button */}
        {/*  type='button' */}
        {/*  className={styles.menu__item} */}
        {/* > */}
        {/*  <svg className={styles.menuButton}> */}
        {/*    <use */}
        {/*      xlinkHref={`${FaqSVG}#faqSVG`} */}
        {/*      href={`${FaqSVG}#faqSVG`} */}
        {/*    /> */}
        {/*  </svg> */}
        {/*  {t('FAQ')} */}
        {/* </button> */}
        <Link
          to='/user-manual'
        >
          <button
            type='button'
            className={styles.menu__item}
          >
            <svg className={styles.menuButton}>
              <use
                xlinkHref={`${ManualSVG}#manualSVG`}
                href={`${ManualSVG}#manualSVG`}
              />
            </svg>
            {t('Application manual')}
          </button>
        </Link>
        <a
          href={HELP_DESK_URL}
          target='_blank'
          className={styles.menu__item}
          rel='noreferrer'
        >
          <svg>
            <use
              xlinkHref={`${SupportSVG}#supportSVG`}
              href={`${SupportSVG}#supportSVG`}
            />
          </svg>
          {t('Help Desk')}
        </a>
      </div>
    </Popover>
  );
};
export default SupportResourcesMenu;
