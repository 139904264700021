import { debounce, Tooltip, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import apiClient from '../../../../../apiClient.ts';
import AngleDownSVG from '../../../../../public/media/angle-down.svg';
import FilterSVG from '../../../../../public/media/Filter.svg';
import ChartSVG from '../../../../../public/media/chart.svg';
import InfoSVG from '../../../../../public/media/info.svg';
import ListSVG from '../../../../../public/media/list.svg';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import CheckboxItem from '../../../../UIKit/CheckboxItem/CheckboxItem';
import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover';
import PopoverOptions from '../../../../UIKit/PopoverOptions/PopoverOptions';
import ToggleButtonGroup from '../../../../UIKit/ToggleButtonGroup/ToggleButtonGroup';
import {
  Categories, categoriesFilter, FilterInternal, internalFilter,
} from '../deliverablesFilter.atom.ts';
import {
  DeliverableNewProjectDeliverablesResponse,
  DeliverableStatus, Domain, Scores,
} from '../types.ts';
import styles from './DeliverablesList.module.scss';
import DomainIcon from './DomainIcon.tsx';
import { NewProjectContext } from '../../types.ts';
import { ProjectResource } from '../../../Projects/types.ts';
import { MOBILE_MEDIA_QUERY } from '../../../../../constants.ts';
import { useCustomTranslation } from '../../../../../useAppTranslate.tsx';

type FormValues = {
  [key: string]: DeliverableNewProjectDeliverablesResponse
};

enum DomainsScrollGradient {
  NONE,
  LEFT,
  RIGHT,
  FULL,
}

type DeliverablesListProps = {
  data: DeliverableNewProjectDeliverablesResponse[],
  domains: Domain[],
  updateScores: (deliverable: DeliverableNewProjectDeliverablesResponse, increase: boolean) => void,
  scores: Scores | null,
  isReadOnly?: boolean,
  setBulkUpdateWorking: (value: boolean) => void,
  openScoreModal: () => void
};

type UpdateDeliverableProps = { id: number, status: DeliverableStatus };

type BulkUpdateDeliverableProps = {
  status: number,
  id: number,
}[];

enum ModifyType {
  ALL,
  NONE,
  INTERNAL,
  EXTERNAL,
}

const EMPTY_SCORE_KEY = 100;

const DeliverablesList = ({
  data, domains, updateScores, scores: originalScores, isReadOnly, setBulkUpdateWorking, openScoreModal,
}: DeliverablesListProps) => {
  const {
    progressAtoms, projectAtom, setInteractionBlocker, setPollingSettings,
  } = useOutletContext<NewProjectContext>();
  const [project, setProject] = useAtom(projectAtom);
  const { t } = useCustomTranslation();
  const { clientId, projectId } = useParams();
  const navigate = useNavigate();

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  const emptyScore = {
    EMPTY_SCORE_KEY: {
      id: EMPTY_SCORE_KEY,
      caption: 'Without category',
      checked: true,
      values: {
        total: 0,
        selected: 0,
      },
    },
  };

  const scores = useMemo(() => ({ ...originalScores, ...emptyScore }), [originalScores]);
  const completeSetup = async () => {
    if (project.step > 2) {
      setPollingSettings(prev => ({
        callback: prev.callback ?? null,
        isTriggered: true,
      }));
      return navigate(`/d/client/${clientId}/project/${projectId}/summary`);
    }

    try {
      const { statusCode, response } = await apiClient.put<{ data: ProjectResource }>(`projects/${projectId}/complete`);
      if (statusCode === 200) {
        setInteractionBlocker({ isVisible: true, callback: null });
        setProject({ ...project, ...response.data });
        setPollingSettings(prev => ({
          callback: prev.callback ?? null,
          isTriggered: true,
        }));
        return navigate(`/d/client/${clientId}/project/${projectId}/summary`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateDeliverable = async ({ id, status }: UpdateDeliverableProps) => {
    try {
      const { statusCode } = await apiClient.put<{
        url: string
      }>(`projects/${projectId}/deliverables/${id}`, { body: JSON.stringify({ status }) });
      return statusCode;
    } catch (e) {
      console.error(e);
    }
  };

  /* Domains linked navigation */
  const [activeDomains, setActiveDomains] = useState<number[]>([domains[0]?.id]);
  const domainsRef = useRef<(HTMLElement)[]>([]);
  const deliverablesListRef = useRef<HTMLDivElement>(null);
  const deliverablesHeaderRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (deliverablesListRef.current) {
      const listFooterHeight = 74;
      const deliverablesList = deliverablesListRef.current;
      const handleScroll = debounce(() => {
        let currentDomains: number[] = [];
        domainsRef.current.forEach((element: HTMLElement) => {
          if (element) {
            const { top, bottom } = element.getBoundingClientRect();
            const { top: deliverablesListTop, height: deliverablesListHeight } = deliverablesList.getBoundingClientRect();

            if ((top <= deliverablesListTop && bottom > deliverablesListTop)
              || (
                (top <= deliverablesListHeight + deliverablesListTop - listFooterHeight)
                && top > deliverablesListTop
              )) {
              currentDomains.push(Number(element.dataset.id));
            }
          }
        });
        setActiveDomains(currentDomains);
      }, 50);

      deliverablesList?.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        deliverablesList?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [deliverablesListRef.current]);

  const onActiveSectionChange = (id: number) => {
    domainsRef.current[id]?.scrollIntoView();
  };
  /* END Domains linked navigation */

  /* Formik data */
  const initialValues: FormValues = data.reduce((result: FormValues, deliverable) => {
    result[deliverable.id] = deliverable;
    return result;
  }, {});

  const {
    values, handleSubmit, setFieldValue, setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
    },
  });
  /* END Formik data */

  /* Filter deliverables */
  const [filterCategories, setFilterCategories] = useAtom(categoriesFilter);

  useEffect(() => {
    filterCategories.projectId !== +projectId! && setFilterCategories({
      projectId: +projectId!,
      quantity: Object.keys(scores).length,
      categories: Object.values(scores)
        .reduce((result: Categories, score) => {
          result[score.id] = { ...score, checked: true };
          return result;
        }, {}),
    });
  }, []);

  const [filterInternal, setFilterInternal] = useAtom(internalFilter);

  useEffect(() => {
    filterInternal.projectId !== +projectId! && setFilterInternal({
      projectId: +projectId!,
      type: FilterInternal.ALL,
    });
  }, []);

  const filteredDeliverables = Object.values(values)
    .filter(deliverable => { // Filter by checked scores
      if (deliverable.scores.length === 0 && filterCategories.categories?.[EMPTY_SCORE_KEY]?.checked) { // return deliverable without scores
        return deliverable;
      }
      return deliverable.scores
        .some(score => filterCategories.categories && Object.values(filterCategories.categories)
          .filter(category => category.checked)
          .some(category => category.id === score.id));
    })
    .filter(deliverable => { // Filter by internal/external or unchecked
      if (filterInternal.type === FilterInternal.EXTERNAL) {
        return values[deliverable.id].status.value === DeliverableStatus.EXTERNAL;
      } else if (filterInternal.type === FilterInternal.INTERNAL) {
        return values[deliverable.id].status.value === DeliverableStatus.INTERNAL;
      } else if (filterInternal.type === FilterInternal.UNCHECKED) {
        return values[deliverable.id].status.value === DeliverableStatus.DISABLED;
      } else {
        return true;
      }
    });

  const getFilterText = (filter: FilterInternal) => ({
    [FilterInternal.UNCHECKED]: 'Filter',
    [FilterInternal.ALL]: 'Show All',
    [FilterInternal.INTERNAL]: 'Show only Internal',
    [FilterInternal.EXTERNAL]: 'Show only External',
  }[filter]);

  const filterInternalText = useMemo(() => getFilterText(filterInternal.type), [filterInternal.type]);

  const filterDeliverables = (type: FilterInternal) => {
    setFilterInternal(prev => ({ ...prev, type }));
  };

  const filterInternalOptions = [FilterInternal.INTERNAL, FilterInternal.EXTERNAL, FilterInternal.ALL]
    .map((filter, key) => ({
      id: key,
      title: t(getFilterText(filter)),
      handler: () => filterDeliverables(filter),
    }));

  const updateAllCategories = (type: 'select' | 'deselect') => {
    const isSelect = type === 'select';
    setFilterCategories(prev => {
      const updatedData = { ...prev.categories };
      Object.values(updatedData).forEach(obj => {
        obj.checked = isSelect;
      });
      return { ...prev, quantity: isSelect ? Object.values(updatedData).length : 0, categories: updatedData };
    });
  };
  /* END Filter deliverables */

  /* Tab progress update */
  const updateProgress = useSetAtom(progressAtoms.deliverables);
  const [fullProgress, setFullProgress] = useState<boolean>(project.step > 2);

  useEffect(() => {
    if (deliverablesListRef.current && filterCategories?.categories) {
      let currentProgress = project.step > 2 ? 100 : 0;
      const deliverablesList = deliverablesListRef.current;

      const {
        scrollHeight, clientHeight,
      } = deliverablesList;

      const handleScroll = () => {
        const { scrollTop } = deliverablesList;
        const scrollableDistance = scrollHeight - clientHeight;
        const scrollProgress = Math.round((scrollTop / scrollableDistance) * 100);
        if (scrollProgress > currentProgress) {
          currentProgress = scrollProgress;
          updateProgress(currentProgress);
          if (currentProgress >= 100) {
            updateProgress(100);
            setFullProgress(true);
            deliverablesList.removeEventListener('scroll', handleScroll);
          }
        }
      };
      if (scrollHeight <= clientHeight) {
        updateProgress(100);
        setFullProgress(true);
        deliverablesList.removeEventListener('scroll', handleScroll);
      }

      deliverablesList?.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        deliverablesList?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [deliverablesListRef.current, filterCategories?.categories]);
  /* END Tab progress update */

  /* Deliverables bulk update */
  const bulkUpdateDeliverable = async (deliverables: BulkUpdateDeliverableProps) => {
    try {
      const { statusCode } = await apiClient.patch<{
        url: string
      }>(`projects/${projectId}/update-deliverables-status-bulk`, { body: JSON.stringify({ deliverables }) });
      return statusCode;
    } catch (e) {
      console.error(e);
    } finally {
      setBulkUpdateWorking(false);
      mutate(
        (key: any[]) => key.includes(`projects/${project.id}/scores?page=all`),
        undefined,
        { revalidate: true },
      );
    }
  };

  const modifyAnswers = (value: ModifyType) => {
    setBulkUpdateWorking(true);
    const isNeedFilter = ModifyType.ALL === value || ModifyType.NONE === value;
    const accessibleDomainsIds = domains.filter(domain => domain.is_editable).map(domain => domain.id);
    const accessibleDeliverables = filteredDeliverables
      .filter(deliverable => accessibleDomainsIds.includes(deliverable.domain_id));
    accessibleDeliverables
      .filter(deliverable => (isNeedFilter ? !deliverable.is_mandatory : true))
      .forEach(deliverable => {
        const oldValue = deliverable.status.value;
        const newValue: DeliverableStatus = value === ModifyType.NONE ? DeliverableStatus.DISABLED
          : value === ModifyType.ALL ? (oldValue === DeliverableStatus.DISABLED
            ? DeliverableStatus.INTERNAL : oldValue)
            : value === ModifyType.EXTERNAL ? (oldValue !== DeliverableStatus.DISABLED
              ? DeliverableStatus.EXTERNAL : oldValue)
              : value === ModifyType.INTERNAL
                ? (oldValue !== DeliverableStatus.DISABLED
                  ? DeliverableStatus.INTERNAL : oldValue)
                : oldValue;
        setFieldValue(`${deliverable.id}`, {
          ...values[deliverable.id],
          status: {
            ...values[deliverable.id].status,
            value: newValue,
          },
        });
      });
    bulkUpdateDeliverable(accessibleDeliverables.map(deliverable => ({
      id: deliverable.id, status: value,
    })));
  };

  const contextMenuOptions = [
    {
      id: 0,
      title: t('Set all items as “Internal”'),
      handler: () => modifyAnswers(ModifyType.INTERNAL),
    },
    {
      id: 1,
      title: t('Set all items as “External”'),
      handler: () => modifyAnswers(ModifyType.EXTERNAL),
    },
    {
      id: 2,
      title: t('Select all items'),
      handler: () => modifyAnswers(ModifyType.ALL),
    },
    {
      id: 3,
      title: t('Deselect all items'),
      handler: () => modifyAnswers(ModifyType.NONE),
    },
  ];
  /* END Deliverables bulk update */

  /* Add scroll gradient to tablet domains list */
  const domainsList = useRef<HTMLUListElement>(null);
  const domainsListWrap = useRef<HTMLDivElement>(null);

  const [domainsScrollGradient, setDomainsScrollGradient] = useState(DomainsScrollGradient.NONE);
  useEffect(() => {
    const handleScroll = () => {
      if (domainsList.current) {
        if (domainsList.current.offsetWidth >= domainsList.current.scrollWidth) {
          setDomainsScrollGradient(DomainsScrollGradient.NONE);
        } else if (domainsList.current.scrollLeft > 0
          && domainsList.current.scrollLeft + domainsList.current.offsetWidth < domainsList.current.scrollWidth) {
          setDomainsScrollGradient(DomainsScrollGradient.FULL);
        } else if (domainsList.current.scrollLeft > 0) {
          setDomainsScrollGradient(DomainsScrollGradient.LEFT);
        } else if (domainsList.current.scrollLeft === 0) {
          setDomainsScrollGradient(DomainsScrollGradient.RIGHT);
        }
      }
    };

    const domainsListCurrent = (domainsList.current as HTMLElement);
    domainsListCurrent?.addEventListener('scroll', handleScroll);

    return () => {
      domainsListCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, [domainsList.current]);
  /* END Add scroll gradient to tablet domains list */

  const nextStepDisabled = isReadOnly && project.step < 3;

  const renderPopoverOptions = () => domains.map((domain, index) => ({
    id: index,
    title: (
      <span className={classNames(styles.popoverOption, {
        [styles.activeOption]: activeDomains.includes(domain.id),
      })}
      >
        <DomainIcon iconName={domain.icon} />
        <span>{domain.caption}</span>
      </span>
    ),
    handler: () => onActiveSectionChange(domain.id),
  }));

  const renderPopoverButton = () => {
    if (activeDomains.length < 0) return null;

    const defaultDomain = domains[0];
    const activeDomain = domains.find(domain => domain.id === activeDomains[0]) ?? defaultDomain;

    if (!activeDomain) return null;

    return (
      <div className={styles.popoverButton__content}>
        <DomainIcon iconName={activeDomain.icon} />
        <span>{activeDomain.caption}</span>
        <svg className={styles.popoverButton__arrow}>
          <use
            xlinkHref={`${AngleDownSVG}#angleDownSVG`}
            href={`${AngleDownSVG}#angleDownSVG`}
          />
        </svg>
      </div>
    );
  };

  return (
    <>
      <aside className={styles.domains}>
        {!isMobileDevice && <h5 className={classNames(styles.title, styles.title_domains)}>{t('Domains')}</h5>}
        <div
          ref={domainsListWrap}
          className={classNames(styles.domains__list__wrap, {
            [styles.leftScrolled]: domainsScrollGradient === DomainsScrollGradient.LEFT
            || domainsScrollGradient === DomainsScrollGradient.FULL,
            [styles.rightScrolled]: domainsScrollGradient === DomainsScrollGradient.RIGHT
            || domainsScrollGradient === DomainsScrollGradient.FULL,
          })}
        >
          {isMobileDevice ? (
            <>
              <PopoverOptions
                customButton={renderPopoverButton()}
                options={renderPopoverOptions()}
                paperClassName={styles.domains__popoverPaper}
                placement={PopoverPlacement.CONTEXT_MENU_LEFT}
                className={styles.domains__popover}
              />
              <Button
                variant={ButtonVariants.SECONDARY}
                icon={(
                  <svg className={styles.popoverButton__arrow}>
                    <use
                      xlinkHref={`${ChartSVG}#chartSVG`}
                      href={`${ChartSVG}#chartSVG`}
                    />
                  </svg>
                )}
                iconSize={{ width: 20, height: 20 }}
                className={styles.domains__scoreButton}
                onClick={openScoreModal}
              >
                {t('Evaluation')}
              </Button>
            </>
          ) : (
            <ul
              className={styles.domains__list}
              ref={domainsList}
            >
              {domains.map(domain => (
                <li key={domain.id}>
                  <button
                    type='button'
                    onClick={() => onActiveSectionChange(domain.id)}
                    className={classNames(styles.domain__item, {
                      [styles.active]: activeDomains.includes(domain.id),
                    })}
                  >
                    <DomainIcon iconName={domain.icon} />
                    {domain.caption}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </aside>
      <div
        className={styles.deliverables}
        ref={deliverablesListRef}
      >
        <header
          className={styles.deliverables__header}
          ref={deliverablesHeaderRef}
        >
          <div className={styles.deliverables__header__section}>
            <h5 className={styles.title}>{t('Select deliverables')}</h5>
            {!isReadOnly && (
              <PopoverOptions
                options={contextMenuOptions}
                buttonClassName={styles.deliverables__optionsButton}
                paperClassName={styles.deliverables__options}
              />
            )}
          </div>
          <div
            className={classNames(styles.deliverables__header__section, styles.deliverables__header__section_filters)}
          >
            <Popover
              paperClassName={styles.filteredCategoriesPaper}
              placement={PopoverPlacement.SELECT}
              triggerButton={(
                <div className={classNames(styles.filterButton, styles.filterCategoriesButton)}>
                  <svg className={styles.filterSVG}>
                    <use
                      xlinkHref={`${ListSVG}#listSVG`}
                      href={`${ListSVG}#listSVG`}
                    />
                  </svg>
                  {t('Selected categories')}
                  {' '}
                  {filterCategories.quantity && `(${filterCategories.quantity})`}
                  <svg className={styles.angleSVG}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
              )}
            >
              <div className={styles.filteredCategoriesPaper__content}>
                <h6 className={styles.filteredCategoriesTitle}>{t('Filter by')}</h6>
                <div className={styles.bulkFilterButtons}>
                  <button
                    type='button'
                    className={styles.bulkFilterButton}
                    onClick={() => updateAllCategories('select')}
                  >
                    {t('Select all')}
                  </button>
                  <button
                    type='button'
                    className={styles.bulkFilterButton}
                    onClick={() => updateAllCategories('deselect')}
                  >
                    {t('Deselect all')}
                  </button>
                </div>
                {filterCategories.categories && Object.values(scores ?? {})?.sort((a, b) => {
                  if (a.id === EMPTY_SCORE_KEY) return 1; // Without category always should be the last
                  if (b.id === EMPTY_SCORE_KEY) return -1;
                  return a.caption.localeCompare(b.caption);
                }).map(score => (
                  filterCategories.categories?.[score.id] && (
                    <CheckboxItem
                      key={score.id}
                      value={filterCategories.categories[score.id].checked}
                      onChange={(e) => {
                        setFilterCategories(prev => ({
                          ...prev,
                          quantity: e.target.checked ? prev.quantity! + 1 : prev.quantity! - 1,
                          categories: {
                            ...prev.categories,
                            [score.id]: { ...prev.categories?.[score.id], checked: e.target.checked },
                          },
                        }));
                      }}
                      label={score.caption}
                      fontSize={12}
                    />
                  )
                ))}
              </div>
            </Popover>
            <PopoverOptions
              customButton={(
                <div className={classNames(styles.filterButton, styles.filterInternalButton)}>
                  <svg className={styles.filterSVG}>
                    <use
                      xlinkHref={`${FilterSVG}#filterSVG`}
                      href={`${FilterSVG}#filterSVG`}
                    />
                  </svg>
                  {t(filterInternalText)}
                  <svg className={styles.angleSVG}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
              )}
              options={filterInternalOptions}
              paperClassName={styles.filterInternalPaper}
            />
          </div>
        </header>
        <form
          onSubmit={handleSubmit}
          className={styles.deliverables__list}
        >
          {filteredDeliverables.length === 0 && (
            <p
              className={styles.noDeliverables}
            >
              {filterInternal.type === FilterInternal.UNCHECKED ? 'You reached your full potential' : 'No deliverables found'}
            </p>
          )}
          {domains.map(domain => {
            const domainDeliverables = filteredDeliverables
              .filter(deliverable => deliverable.domain_id === domain.id);
            const isSelectedAll = !domainDeliverables.some(deliverable => deliverable.status.value === DeliverableStatus.DISABLED);
            const isDomainSelectIndeterminate = domainDeliverables
              .some(deliverable => deliverable.status.value === DeliverableStatus.INTERNAL
                || deliverable.status.value === DeliverableStatus.EXTERNAL)
            && domainDeliverables
              .some(deliverable => deliverable.status.value === DeliverableStatus.DISABLED);

            const activeDelieverables = domainDeliverables.filter(deliverable => deliverable.status.value !== DeliverableStatus.DISABLED);
            const domainTogglevalue = !activeDelieverables.length
              ? null : activeDelieverables.every(deliverable => deliverable.status.value === DeliverableStatus.INTERNAL)
                ? t('Internal') : activeDelieverables.every(deliverable => deliverable.status.value === DeliverableStatus.EXTERNAL)
                  ? t('External') : null;
            return (
              domainDeliverables.length > 0 && (
                <section
                  className={styles.deliverables__section}
                  ref={(element: HTMLElement) => {
                    domainsRef.current[+domain.id] = element;
                  }}
                  data-id={domain.id}
                  key={domain.id}
                >
                  <div className={classNames(styles.deliverables__heading, styles.deliverables__domain)}>
                    {isReadOnly ? (<p>{domain.caption}</p>) : (
                      <CheckboxItem
                        value={isSelectedAll}
                        indeterminate={isDomainSelectIndeterminate}
                        onChange={() => {
                          const deliverablesForUpdate = domainDeliverables
                            .filter(deliverable => !deliverable.is_mandatory)
                            .map(deliverable => ({
                              id: deliverable.id, status: isSelectedAll ? DeliverableStatus.DISABLED : DeliverableStatus.INTERNAL,
                            }));
                          Object.values(deliverablesForUpdate).forEach((deliverable) => {
                            setFieldValue(`${deliverable.id}`, {
                              ...values[deliverable.id],
                              status: {
                                ...values[deliverable.id].status,
                                value: isSelectedAll ? DeliverableStatus.DISABLED : DeliverableStatus.INTERNAL,
                              },
                            });
                          });
                          bulkUpdateDeliverable(deliverablesForUpdate);
                        }}
                        disabled={!domain.is_editable}
                        label={domain.caption}
                        labelClassName={styles.deliverables__domain}
                      />
                    )}
                    {/* TODO: adde styles for mobile */}
                    {!isReadOnly && (
                    <div className={classNames(styles.deliverables__heading__toggle)}>
                      <p>{t('Change all')}</p>
                      <ToggleButtonGroup
                        disabled={
                          domainDeliverables.every(deliverable => deliverable.status.value === DeliverableStatus.DISABLED)
                          || !domain.is_editable
                        }
                        options={[t('Internal'), t('External')]}
                        value={domainTogglevalue}
                        setValue={(buttonValue) => {
                          const deliverablesForUpdate = domainDeliverables
                            .filter(deliverable => deliverable.status.value !== DeliverableStatus.DISABLED)
                            .map(deliverable => ({
                              id: deliverable.id,
                              status: buttonValue === t('Internal')
                                ? DeliverableStatus.INTERNAL
                                : DeliverableStatus.EXTERNAL,
                            }));
                          const newFormikValues = {
                            ...values,
                            ...deliverablesForUpdate.reduce((acc, deliverable) => {
                              acc[deliverable.id] = {
                                ...values[deliverable.id],
                                status: {
                                  caption: buttonValue === t('Internal') ? 'Internal' : 'External',
                                  value: buttonValue === t('Internal')
                                    ? DeliverableStatus.INTERNAL : DeliverableStatus.EXTERNAL,
                                },
                              };
                              return acc;
                            }, {} as FormValues),
                          };

                          setValues(newFormikValues);
                          bulkUpdateDeliverable(deliverablesForUpdate);
                        }}
                      />
                    </div>
                    )}
                  </div>
                  {domainDeliverables
                    .map(deliverable => {
                      const isStatusDisabled = values[deliverable.id].status.value === DeliverableStatus.DISABLED;
                      if (isStatusDisabled && isReadOnly) return null;
                      return (
                        <div
                          className={classNames(styles.deliverable, {
                            [styles.readOnly]: isReadOnly,
                          })}
                          key={deliverable.id}
                        >
                          {isReadOnly ? (<p>{deliverable.caption}</p>) : (
                            <Tooltip
                              arrow
                              title={deliverable.is_mandatory ? t('This deliverable is mandatory') : null}
                              placement='bottom-start'
                              enterTouchDelay={0}
                            >
                              <span>
                                <CheckboxItem
                                  value={values[deliverable.id].status.value === DeliverableStatus.EXTERNAL
                                  || values[deliverable.id].status.value === DeliverableStatus.INTERNAL}
                                  onChange={(e) => {
                                    setFieldValue(`${deliverable.id}`, {
                                      ...values[deliverable.id],
                                      status: {
                                        ...values[deliverable.id].status,
                                        value: e.target.checked ? DeliverableStatus.INTERNAL : DeliverableStatus.DISABLED,
                                      },
                                    });
                                    updateDeliverable({
                                      id: deliverable.id,
                                      status: e.target.checked ? DeliverableStatus.INTERNAL : DeliverableStatus.DISABLED,
                                    });
                                    updateScores(deliverable, e.target.checked);
                                  }}
                                  label={deliverable.caption}
                                  fontSize={12}
                                  disabled={!!deliverable.is_mandatory || !domain.is_editable}
                                />
                              </span>
                            </Tooltip>
                          )}
                          <div className={classNames(styles.deliverable__status, {
                            [styles.readOnly]: isReadOnly,
                          })}
                          >
                            {isReadOnly ? (
                              <p className={classNames(styles.deliverable__readOnlyStatus, {
                                [styles.external]:
                                values[deliverable.id].status.value === DeliverableStatus.EXTERNAL,
                              })}
                              >
                                {values[deliverable.id].status.caption}
                              </p>
                            ) : (
                              <ToggleButtonGroup
                                disabled={values[deliverable.id].status.value === DeliverableStatus.DISABLED || !domain.is_editable}
                                options={[t('Internal'), t('External')]}
                                value={values[deliverable.id].status.value === DeliverableStatus.DISABLED
                                  ? null : values[deliverable.id].status.value === DeliverableStatus.INTERNAL
                                    ? t('Internal') : t('External')}
                                setValue={(buttonValue) => {
                                  setFieldValue(`${deliverable.id}`, {
                                    ...values[deliverable.id],
                                    status: {
                                      caption: buttonValue === t('Internal') ? 'Internal' : 'External',
                                      value: buttonValue === t('Internal')
                                        ? DeliverableStatus.INTERNAL : DeliverableStatus.EXTERNAL,
                                    },
                                  });
                                  updateDeliverable({
                                    id: deliverable.id,
                                    status: buttonValue === t('Internal')
                                      ? DeliverableStatus.INTERNAL : DeliverableStatus.EXTERNAL,
                                  });
                                }}
                              />
                            )}
                            <Tooltip
                              arrow
                              title={(
                                deliverable.description?.length > 0 ? (
                                  <div dangerouslySetInnerHTML={{ __html: deliverable.description }} />
                                ) : <p>{t('Description is not available')}</p>
                              )}
                              placement='bottom-start'
                            >
                              <svg className={styles.deliverable__infoIcon}>
                                <use
                                  xlinkHref={`${InfoSVG}#infoSVG`}
                                  href={`${InfoSVG}#infoSVG`}
                                />
                              </svg>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                </section>
              )
            );
          })}
        </form>
        <footer className={styles.footer}>
          {!isReadOnly && (
            <div>
              {project.step <= 2 && <p className={styles.footer__title}>{t('Complete the setup of deliverables')}</p>}
              <p
                className={styles.footer__text}
              >
                {project.step > 2
                  ? t(`Please review any changes, check your selection of deliverables and scroll to the end
                   of the list to complete the configuration of the project.`)
                  : t('Familiarize yourself and scroll to the end of the list to complete the creation of the project')}
              </p>
            </div>
          )}
          <Button
            onClick={() => (isReadOnly ? navigate(`/d/client/${clientId}/project/${projectId}/summary`) : completeSetup())}
            disabled={!fullProgress || nextStepDisabled}
            className={classNames({
              [styles.footer__button_readOnly]: isReadOnly,
            })}
          >
            {t('Next')}
          </Button>
        </footer>
      </div>
    </>
  );
};

export default DeliverablesList;
